import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/style.scss";
import ModalProvider from "./context/ModalContext";
import TagManager from "react-gtm-module";
import {GTMcookieOK} from "./hook/cookie/dataLayer.service";

const tagManagerArgs = {
    gtmId: "GTM-000000",
};

TagManager.initialize(tagManagerArgs);

GTMcookieOK();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ModalProvider>
            <App/>
        </ModalProvider>
    </React.StrictMode>
);
