import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import Layout from "../components/Layout/Layout";

import HeroMobileLV from "./img/lv/hero-mobile.png";
import HeroDesktopLV from "./img/lv/hero.png";
import veloLogoSvg from "../assets/VeloPage/velo-logo.svg";
import veloHeroPack from "../assets/VeloPage/desktop/velo-hero.png";
import veloHeroPackMobile from "../assets/VeloPage/velo-hero.png";
import allProductsImage from "../assets/VeloPage/products.png";
import VeloMiniLV from "./img/lv/velo-mini.png";
import VeloSlimLV from "./img/lv/velo-four.png";
import VeloSlimCoolLV from "./img/lv/velo-slim-black.png";
import VeloSlimCoolLVMobile from "./img/lv/velo-slim-black-mobile.png";
import VeloBlackLV from "./img/lv/velo-black.png";
import VeloMiniMobileLV from "./img/lv/velo-mini-mobile.jpg";
import VeloSlimMobileLV from "./img/lv/velo-four-mobile.png";
import VeloBlackMobileLV from "./img/lv/velo-black-mobile.png";

import tutorialSvg1 from "../assets/VeloPage/tutorial1.png";
import tutorialSvg2 from "../assets/VeloPage/tutorial2.png";
import tutorialSvg3 from "../assets/VeloPage/tutorial-icon-3.svg";
import tutorialSvg4 from "../assets/VeloPage/tutorial4.png";

import findSectionImageLV from "./img/lv/footer.jpg";
import findSectionImageMobileLV from "./img/lv/footer-mobile.jpg";

// import EE from '../langs/ee.json';
import LV from '../langs/lv.json';

import Icon1 from "./img/icon01.png";
import Icon2 from "./img/icon02.png";
import Icon3 from "./img/icon03.png";
import Icon4 from "./img/icon04.png";
import Icon5 from "./img/icon05.png";
import Icon6 from "./img/icon06.png";
import Icon7 from "./img/icon07.png";
import Icon8 from "./img/icon08.png";

const VeloPage = () => {
  const {lang} = useParams();
  const data = LV;
  const heroMobile = HeroMobileLV;
  const heroDesktop = HeroDesktopLV;

  const VMini = VeloMiniLV;
  const VMiniMobile = VeloMiniMobileLV;
  const VSlim = VeloSlimLV;
  const VSlimMobile = VeloSlimMobileLV;
  const VBlack = VeloBlackLV;
  const VBlackMobile = VeloBlackMobileLV;
  const VBlackCool = VeloSlimCoolLV;
  const VBlackCoolMobile = VeloSlimCoolLVMobile;

  const footerImage = findSectionImageLV;
  const footerImageMobile = findSectionImageMobileLV;

  const currentURL = window.location.href;

  return (
    <Layout footerType="black" langData={data}>
      <section className="velo-page">
        <div className="velo-page__images">
          <img
            className="velo-page__image"
            src={heroMobile}
            alt="SASZETKI NIKOTYNOWE."
          />
          <img
            className="velo-page__image--destkop"
            src={heroDesktop}
            alt="SASZETKI NIKOTYNOWE."
          />
        </div>
        <div className="velo-intro" id="what-is-velo">
          <span className="velo-intro__logo test">
            <img src={veloLogoSvg} alt="Veleo" />
          </span>
          <div className="container--center">
            <div className="velo-intro__wrapper">
              <h1 className="velo-intro__title">{ data.experience.title }</h1>
              <span className="velo-intro__image">
                <img
                  className="velo-intro__image--desktop"
                  src={veloHeroPack}
                  alt="velo"
                />
                <img
                  className="velo-intro__image--mobile"
                  src={veloHeroPackMobile}
                  alt="velo"
                />
              </span>
              <div className="velo-intro__content">
                <p className="velo-intro__desc">
                { data.experience.subtitle }
                </p>
              </div>
              <h2>{ data.experience.title_2 }</h2>
            </div>
            <div className="points">
              { data.experience.points.map((item, key)=>{
                return (
                  <div className="point" key={'points'+key}>
                    <span className="point-id">{ item.id }</span>
                    <p><strong>{item.title}</strong><br/>{item.desc}</p>
                  </div>
                )
              })}
            </div>
            <h2>{ data.experience.title_3 }</h2>
            <div className="icons">
              <div className="icon">
                <img src={Icon1} />
                <div className="name">{ data.experience.icons[0] }</div>
              </div>
              <div className="icon">
                <img src={Icon2} />
                <div className="name">{ data.experience.icons[1] }</div>
              </div>
              <div className="icon">
                <img src={Icon3} />
                <div className="name">{ data.experience.icons[2] }</div>
              </div>
              <div className="icon">
                <img src={Icon4} />
                <div className="name">{ data.experience.icons[3] }</div>
              </div>
            </div>
            <h2>{ data.experience.title_4 }</h2>
            <h3 className="subheader">{ data.experience.subtitle_4 }</h3>
            <div className="icons">
              <div className="icon">
                <img src={Icon5} />
                <div className="name">{ data.experience.icons_2[0] }</div>
              </div>
              <div className="icon">
                <img src={Icon6} />
                <div className="name">{ data.experience.icons_2[1] }</div>
              </div>
              <div className="icon">
                <img src={Icon7} />
                <div className="name">{ data.experience.icons_2[2] }</div>
              </div>
              <div className="icon">
                <img src={Icon8} />
                <div className="name">{ data.experience.icons_2[3] }</div>
              </div>
            </div>
          </div>
        </div>
        <div className="velo-products" id="velo-products">
          <div className="container--center">
            <div className="velo-products__image">
              <div className="velo-products__image--photo">
                <img src={allProductsImage} alt="polar mint pack" />
              </div>
            </div>
            <h2 className="velo-products__title">{ data.product_sets.title }</h2>
          </div>
          <div className="container--center">
            <ul className="velo-product__list">
              <li className="velo-product__list-item">
                <div className="velo-product__content">
                  <h3 className="velo-product__category velo-product__category--nospace blue-text">{ data.product_sets.subtitle }</h3>
                  <div className="velo-product__photo velo-product__photo--full">
                    <picture>
                      <source media="(max-width: 719px)" srcSet={ VMiniMobile }/>
                      <source media="(min-width: 720px)" srcSet={ VMini }/>
                      <img className="velo-product__image" src={ VMini } alt="Velo Mini"/>
                    </picture>
                  </div>
                  <p className="velo-product__desc" dangerouslySetInnerHTML={{__html: data.product_sets.mini.text }}>
                  </p>
                  <p className="velo-product__desc">{ data.product_sets.mini.nicotine }</p>
                  <p className="velo-product__desc">{ data.product_sets.mini.subtext }</p>
                </div>
              </li>
              <li className="velo-product__list-item">
                <div className="velo-product__content">
                  <h3 className="velo-product__category velo-product__category blue-text">{ data.product_sets.coolstorm.title }</h3>
                  <div className="velo-product__photo velo-product__photo--full">
                    <picture>
                      <source media="(max-width: 719px)" srcSet={ VBlackCoolMobile }/>
                      <source media="(min-width: 720px)" srcSet={ VBlackCool }/>
                      <img className="velo-product__image" src={ VBlackCool } alt="Velo Cool Storm"/>
                    </picture>
                  </div>
                  <p className="velo-product__desc" dangerouslySetInnerHTML={{__html: data.product_sets.coolstorm.text }}>
                  </p>
                  <p className="velo-product__desc">{ data.product_sets.coolstorm.nicotine }</p>
                  <p className="velo-product__desc">{ data.product_sets.coolstorm.subtext }</p>
                </div>
              </li>
              <li className="velo-product__list-item">
                <div className="velo-product__content">
                  <h3 className="velo-product__category velo-product__category blue-text">{ data.product_sets.slim.title }</h3>
                  <div className="velo-product__photo velo-product__photo--full">
                    <picture>
                      <source media="(max-width: 719px)" srcSet={ VSlimMobile }/>
                      <source media="(min-width: 720px)" srcSet={ VSlim }/>
                      <img className="velo-product__image" src={ VSlim } alt="Velo Slim"/>
                    </picture>
                  </div>
                  <p className="velo-product__desc" dangerouslySetInnerHTML={{__html: data.product_sets.slim.text }}>
                  </p>
                  <p className="velo-product__desc">{ data.product_sets.slim.nicotine }</p>
                  <p className="velo-product__desc">{ data.product_sets.slim.subtext }</p>
                </div>
              </li>
              <li className="velo-product__list-item">
                <div className="velo-product__content">
                  <h3 className="velo-product__category velo-product__category blue-text">{ data.product_sets.black.title }</h3>
                  <div className="velo-product__photo velo-product__photo--full">
                    <picture>
                      <source media="(max-width: 719px)" srcSet={ VBlackMobile }/>
                      <source media="(min-width: 720px)" srcSet={ VBlack }/>
                      <img className="velo-product__image" src={ VBlack } alt="Velo Black"/>
                    </picture>
                  </div>
                  <p className="velo-product__desc" dangerouslySetInnerHTML={{__html: data.product_sets.black.text }}>
                  </p>
                  <p className="velo-product__desc">{ data.product_sets.black.nicotine }</p>
                  <p className="velo-product__desc">{ data.product_sets.black.subtext }</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="product-cards-section">
          <h2 className="blue-text">{ data.products.title }</h2>
          <ul className="product-cards">
            { data.products.mini.map((item, key) => {
              return (
                <li key={"mini" + key} className="product-card good-for-beginners">
                  <img src={`../assets/product/good_for_beginners.png`} className="good-for-beginners-img" />
                  <img src={`../assets/product/${item.id}.jpg`} />
                  <h3 className="product-title">{item.name}</h3>
                  <p>{item.nicotine}</p>
                  <div className={"strength " + item.color}>
                    {item.strength.map((sitem, skey) => {
                      return <div key={item.id + skey} className={sitem === 1 ? 'full' : "empty"}></div>
                    })}
                  </div>
                  <p className="description">{ item.desc }</p>
                  <p className="info">{ item.info }</p>
                </li>
              )
            })}
          </ul>
          <ul className="product-cards">
            { data.products.slim.map((item, key) => {
              return (
                <li key={"mini" + key} className="product-card">
                  <img src={`../assets/product/${item.id}.jpg`} />
                  <h3 className="product-title">{item.name}</h3>
                  <p>{item.nicotine}</p>
                  <div className={"strength " + item.color}>
                    {item.strength.map((sitem, skey) => {
                      return <div key={item.id + skey} className={sitem === 1 ? 'full' : "empty"}></div>
                    })}
                  </div>
                  <p className="description">{ item.desc }</p>
                  <p className="info">{ item.info }</p>
                </li>
              )
            })}
          </ul>
          <ul className="product-cards">
            { data.products.black.map((item, key) => {
              return (
                <li key={"mini" + key} className="product-card">
                  <img src={`../assets/product/${item.id}.jpg`} />
                  <h3 className="product-title">{item.name}</h3>
                  <p>{item.nicotine}</p>
                  <div className={"strength " + item.color}>
                    {item.strength.map((sitem, skey) => {
                      return <div key={item.id + skey} className={sitem === 1 ? 'full' : "empty"}></div>
                    })}
                  </div>
                  <p className="description">{ item.desc }</p>
                  <p className="info">{ item.info }</p>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="velo-how" id="how-to-use">
          <div className="container--center">
            <h2 className="velo-how__title">
              { data.how_to.title }<span>{ data.how_to.subtitle }</span>
            </h2>
            <ul className="velo-how-tutorial">
              <li className="velo-how-tutorial__step">
                <span className="velo-how-tutorial__icon">
                  <span className="velo-how-tutorial__counter">1.</span>
                  <div className="icon">
                    <img src={tutorialSvg1} alt="tutorial step" />
                  </div>
                </span>
                <p className="velo-how-tutorial__desc">
                  { data.how_to.one }
                </p>
              </li>
              <li className="velo-how-tutorial__step">
                <p className="velo-how-tutorial__desc">
                { data.how_to.two }
                </p>
                <span className="velo-how-tutorial__icon">
                  <span className="velo-how-tutorial__counter">2.</span>
                  <div className="icon">
                    <img src={tutorialSvg2} alt="tutorial step" />
                  </div>
                </span>
              </li>
              <li className="velo-how-tutorial__step">
                <span className="velo-how-tutorial__icon">
                  <span className="velo-how-tutorial__counter">3.</span>
                  <div className="icon">
                    <img className="timer" src={tutorialSvg3} alt="tutorial step" />
                  </div>
                </span>
                <p className="velo-how-tutorial__desc">
                  { data.how_to.three }
                </p>
              </li>
              <li className="velo-how-tutorial__step">
                <p className="velo-how-tutorial__desc">
                  { data.how_to.four }
                </p>
                <span className="velo-how-tutorial__icon">
                  <span className="velo-how-tutorial__counter">4.</span>
                  <div className="icon">
                    <img src={tutorialSvg4} alt="tutorial step" />
                  </div>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="velo-find">
          <div className="velo-find__image">
            <img
              className="velo-find__image--desktop"
              src={footerImage}
              alt=" "
            />
            <img
              className="velo-find__image--mobile"
              src={footerImageMobile}
              alt=" "
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default VeloPage;
