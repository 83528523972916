import GetCookie from './getCookie'


export const GTMcookieOK = () => {
    let flag = false
    window.dataLayer.forEach(cookie => {
        flag = (cookie.event == "GTMcookieOK") ? true : false
    });

    if(!flag && GetCookie('age') && (GetCookie('communication') || GetCookie('performance') || GetCookie('technical'))) {
        window.dataLayer.push({
            event: "GTMcookieOK",
        });
    }
}
