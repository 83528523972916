import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ReactModal from "../Modal/Modal";
import AgeGate from "../AgeGate/AgeGate";
import { useModalContext } from "../../context/ModalContext";
import CookieModal from "../CookieModal/CookieModal";
import { useLocalStorage } from "../../hook/useLocalStorage";
import EE from './../../langs/ee.json';
import LV from './../../langs/lv.json';
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { GetCookie } from '../../hook/cookie';

const Layout = ({ children, footerType, langData }) => {
  const { isOpenCookies, isOpenAgeGate } = useModalContext();
  const [value] = useLocalStorage("cookieOptions");
  const {lang} = useParams();
  const location = useLocation();
  const data = lang === "ee" ? EE : LV;

  useEffect(() => {
    window.scrollTo(0,0);
  }, [location.pathname])

  return (
    <>
      <div className="global-wrapper">
        <Header langData={langData.header} />
        <main>{children}</main>
        <Footer footerType={footerType} langData={langData.footer} lang={lang} />
      </div>
      {!GetCookie('age') ? (
        <ReactModal isOpen={isOpenAgeGate}>
          <AgeGate langData={data} />
        </ReactModal>
      ) : null}
      {!isOpenAgeGate ? (
        <ReactModal
          isOpen={isOpenCookies}
        >
          <CookieModal langData={data} />
          </ReactModal>
      ) : null}
    </>
  );
};

export default Layout;
