import React from "react";
import Layout from "../components/Layout/Layout";
import LV from '../langs/lv.json';

const Forbidden = () => {
  const data = LV;

  return (
    <Layout footerType="black" langData={data}>
      <section className="faq-page">
        <h1>Forbidden</h1>
      </section>
    </Layout>
  );
};

export default Forbidden;
