import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
    Outlet,
} from "react-router-dom";
import VeloPage from "./pages/VeloPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import FaqPage from "./pages/FaqPage";
import MapPage from "./pages/MapPage";
import Forbidden from "./pages/Forbidden";
import axiosClient from "./utils/axios-client";
import { useEffect, useState } from "react";

function App() {

  const [status, setStatus] = useState(200);

  useEffect(() => {
    axiosClient
    .get("/landing")
    .then((res) => {
      setStatus(res.status);
    })
    .catch((err) => {
      setStatus(err.response.status);
    });
  
  }, []);

  const ProtectedRoute = ({ status }) => {
    switch (status) {
      case 403:
        return <Navigate to={"/lv/lv/forbidden"} replace />;
        break;

      default:
        return <Outlet />;
        break;
    }
  };

  return (
      <>
          <Router>
              <Routes>
                <Route path="/" element={<Navigate to="/lv/lv" />} />
                <Route element={<ProtectedRoute status={status} />}>
                  <Route path="/lv/lv" element={<VeloPage />} />
                  <Route path="/lv/lv/privacy-policy" element={<PrivacyPolicyPage />} />
                  <Route path="/lv/lv/faq" element={<FaqPage />} />
                  <Route path="/lv/lv/where-to-buy" element={<MapPage />} />
                </Route>
                <Route path="/lv/lv/forbidden" element={<Forbidden />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
          </Router>
      </>
  );
}

export default App;
