const stores = {
    "type": "FeatureCollection",
    "features": [

        { "type": "Feature", "id": 1, "properties": { "store_name": "AIBE", "address": "Varžu iela 4", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1746599, 56.9542682] } },
        { "type": "Feature", "id": 2, "properties": { "store_name": "AIBE", "address": "Daugavgrīvas iela 112", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0707068, 56.9653946] } },
        { "type": "Feature", "id": 3, "properties": { "store_name": "Latvijas Nafta ", "address": "Jupatovkas iela 4", "postcode": "", "city": "Rēzekne" }, "geometry": { "type": "Point", "coordinates": [27.3531379, 56.5155612] } },
        { "type": "Feature", "id": 4, "properties": { "store_name": "Latvijas Nafta ", "address": "Rūjienas iela   31", "postcode": "", "city": "Valka" }, "geometry": { "type": "Point", "coordinates": [26.0306527, 57.7904747] } },
        { "type": "Feature", "id": 5, "properties": { "store_name": "Latvijas Nafta ", "address": "Smiltenes pagrieziens", "postcode": "", "city": "Launkalnes pagasts" }, "geometry": { "type": "Point", "coordinates": [25.971168, 57.391273] } },
        { "type": "Feature", "id": 6, "properties": { "store_name": "Latvijas Nafta ", "address": "Daugavpils iela 18", "postcode": "", "city": "Dagda" }, "geometry": { "type": "Point", "coordinates": [27.5220436, 56.0910798] } },
        { "type": "Feature", "id": 7, "properties": { "store_name": "Latvijas Nafta ", "address": "Rīgas iela 1", "postcode": "", "city": "Līvāni" }, "geometry": { "type": "Point", "coordinates": [26.1795455, 56.3390982] } },
        { "type": "Feature", "id": 8, "properties": { "store_name": "Latvijas Nafta ", "address": "Stacijas iela 16", "postcode": "", "city": "Lubāna" }, "geometry": { "type": "Point", "coordinates": [26.7084395, 56.9055249] } },
        { "type": "Feature", "id": 9, "properties": { "store_name": "Latvijas Nafta ", "address": "Krustpils iela 1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1902714, 56.9238066] } },
        { "type": "Feature", "id": 10, "properties": { "store_name": "Latvijas Nafta ", "address": "Saules iela 46", "postcode": "", "city": "Madona" }, "geometry": { "type": "Point", "coordinates": [26.229088, 56.860988] } },
        { "type": "Feature", "id": 11, "properties": { "store_name": "Latvijas Nafta ", "address": "Rīgas iela 47", "postcode": "", "city": "Rēzekne" }, "geometry": { "type": "Point", "coordinates": [27.3202516, 56.5197802] } },
        { "type": "Feature", "id": 12, "properties": { "store_name": "Latvijas Nafta ", "address": "Piebalgas iela 2", "postcode": "", "city": "Ergļu pagasts" }, "geometry": { "type": "Point", "coordinates": [25.6488676, 56.8987877] } },
        { "type": "Feature", "id": 13, "properties": { "store_name": "Latvijas Nafta ", "address": "Sauleskalna iela 2", "postcode": "", "city": "Bērzaunes pagasts" }, "geometry": { "type": "Point", "coordinates": [26.0757244, 56.8063021] } },
        { "type": "Feature", "id": 14, "properties": { "store_name": "Latvijas Nafta ", "address": "Cempu iela 4 a", "postcode": "", "city": "Valmiera" }, "geometry": { "type": "Point", "coordinates": [25.4501356, 57.5218489] } },
        { "type": "Feature", "id": 15, "properties": { "store_name": "Latvijas Nafta ", "address": "Salas', Nīcgales Muiža", "postcode": "", "city": "Nīcgales pagasts" }, "geometry": { "type": "Point", "coordinates": [26.3499179, 56.146189] } },
        { "type": "Feature", "id": 16, "properties": { "store_name": "Latvijas Nafta ", "address": "Miera iela 15 a", "postcode": "", "city": "Gulbene" }, "geometry": { "type": "Point", "coordinates": [26.7508367, 57.1816775] } },
        { "type": "Feature", "id": 17, "properties": { "store_name": "Latvijas Nafta ", "address": "Laimdotas iela 23", "postcode": "", "city": "Lielvārde" }, "geometry": { "type": "Point", "coordinates": [24.8273067, 56.7164524] } },
        { "type": "Feature", "id": 18, "properties": { "store_name": "Latvijas Nafta ", "address": "Grenstāles  robežkontroles punkts", "postcode": "", "city": "Brunavas pagasts" }, "geometry": { "type": "Point", "coordinates": [24.3431608, 56.3121831] } },
        { "type": "Feature", "id": 19, "properties": { "store_name": "Latvijas Nafta ", "address": "Uriekstes iela 3", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1146784, 56.988666] } },
        { "type": "Feature", "id": 20, "properties": { "store_name": "Latvijas Nafta ", "address": "Rīgas – Siguldas šos. 4a", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2857514, 57.0023379] } },
        { "type": "Feature", "id": 21, "properties": { "store_name": "Latvijas Nafta ", "address": "Lapsu iela 36", "postcode": "", "city": "Cēsis" }, "geometry": { "type": "Point", "coordinates": [25.2844518, 57.3077479] } },
        { "type": "Feature", "id": 22, "properties": { "store_name": "Latvijas Nafta ", "address": "Ainažu robežkontroles punkts", "postcode": "", "city": "Ainaži" }, "geometry": { "type": "Point", "coordinates": [24.3599992, 57.8600004] } },
        { "type": "Feature", "id": 23, "properties": { "store_name": "Latvijas Nafta ", "address": "Vidzemes šos., 69", "postcode": "", "city": "Sigulda" }, "geometry": { "type": "Point", "coordinates": [24.8696308, 57.1486047] } },
        { "type": "Feature", "id": 24, "properties": { "store_name": "Latvijas Nafta ", "address": "Talsu šos. 1, Sloka, LV-2011", "postcode": "", "city": "Jūrmala" }, "geometry": { "type": "Point", "coordinates": [23.635166, 56.9567633] } },
        { "type": "Feature", "id": 25, "properties": { "store_name": "Latvijas Nafta ", "address": "'Dambji, Piņķi", "postcode": "", "city": "Babītes pagasts" }, "geometry": { "type": "Point", "coordinates": [23.9110019, 56.9443493] } },
        { "type": "Feature", "id": 26, "properties": { "store_name": "Latvijas Nafta ", "address": "Klaipēdas šos.5km", "postcode": "", "city": "Nīcas pagasts" }, "geometry": { "type": "Point", "coordinates": [21.0084063, 56.4783954] } },
        { "type": "Feature", "id": 27, "properties": { "store_name": "Latvijas Nafta ", "address": "Flotes iela 9", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0058075, 56.52334] } },
        { "type": "Feature", "id": 28, "properties": { "store_name": "Latvijas Nafta ", "address": "Daugavpils iela 55", "postcode": "", "city": "Ogre" }, "geometry": { "type": "Point", "coordinates": [24.6312637, 56.7944792] } },
        { "type": "Feature", "id": 29, "properties": { "store_name": "VIADA", "address": "Maskavas 440, Rīga, LV-1063", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2177251, 56.8932903] } },
        { "type": "Feature", "id": 30, "properties": { "store_name": "VIADA", "address": "Šampētera 180, Rīga, LV-1046", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0139662, 56.9305391] } },
        { "type": "Feature", "id": 31, "properties": { "store_name": "VIADA", "address": "Rīgas gatve 65, Ādaži, Ādažu pag., Ādazu nov., LV-2164", "postcode": "", "city": "Ādaži" }, "geometry": { "type": "Point", "coordinates": [24.3140498, 57.0897783] } },
        { "type": "Feature", "id": 32, "properties": { "store_name": "VIADA", "address": "Nometņu 99d, Daugavpils, LV-5414", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.5812041, 55.8648365] } },
        { "type": "Feature", "id": 33, "properties": { "store_name": "VIADA", "address": "Vidzemes 1d, Daugavpils, LV-5422", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.5206577, 55.8944623] } },
        { "type": "Feature", "id": 34, "properties": { "store_name": "VIADA", "address": "Buļļu 72a, Rīga, LV-1067", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0364026, 56.9739115] } },
        { "type": "Feature", "id": 35, "properties": { "store_name": "VIADA", "address": "Atbrīvošanas aleja 174a, Rēzekne, LV-4604", "postcode": "", "city": "Rēzekne" }, "geometry": { "type": "Point", "coordinates": [27.3444145, 56.526277] } },
        { "type": "Feature", "id": 36, "properties": { "store_name": "VIADA", "address": "Vienības 3, Jēkabpils, Jēkabpils nov., LV-5201", "postcode": "", "city": "Jēkabpils" }, "geometry": { "type": "Point", "coordinates": [25.8780219, 56.4947166] } },
        { "type": "Feature", "id": 37, "properties": { "store_name": "VIADA", "address": "Akadēmiķa Mstislava Keldiša 17, Rīga, LV-1021", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2159693, 56.9383677] } },
        { "type": "Feature", "id": 38, "properties": { "store_name": "VIADA", "address": "Krūmu iela 56, Liepāja, LV-3405", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0128123, 56.5343114] } },
        { "type": "Feature", "id": 39, "properties": { "store_name": "VIADA", "address": "Dārzciema 69,Rīga, LV-1073", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1838084, 56.9399469] } },
        { "type": "Feature", "id": 40, "properties": { "store_name": "VIADA", "address": "Piebalgas 79, Ērgļi, Ērgļu pag., LV-4840", "postcode": "", "city": "Ērgļi" }, "geometry": { "type": "Point", "coordinates": [25.65464, 56.905685] } },
        { "type": "Feature", "id": 41, "properties": { "store_name": "VIADA", "address": "Lielā iela 8, Grobiņa, Dienvidkkurzemes nov., LV-3430", "postcode": "", "city": "Grobiņa" }, "geometry": { "type": "Point", "coordinates": [21.1443126, 56.5362457] } },
        { "type": "Feature", "id": 42, "properties": { "store_name": "VIADA", "address": "Ķīšezera iela 31, Rīga, LV-1014", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1734878, 56.9935801] } },
        { "type": "Feature", "id": 43, "properties": { "store_name": "VIADA", "address": "¨Zvirbulīši¨ Vecpiebalga, Vecpiebalgas pag., LV- 4122", "postcode": "", "city": "Vecpiebalga" }, "geometry": { "type": "Point", "coordinates": [25.8107457, 57.0620132] } },
        { "type": "Feature", "id": 44, "properties": { "store_name": "VIADA", "address": "Granīta iela 7a, Rīga, LV-1057", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2168701, 56.9133279] } },
        { "type": "Feature", "id": 45, "properties": { "store_name": "VIADA", "address": "Aleta, Straupe, Straupes pag., Pārgaujas nov., LV-4152", "postcode": "", "city": "Straupe" }, "geometry": { "type": "Point", "coordinates": [24.9514143, 57.351449] } },
        { "type": "Feature", "id": 46, "properties": { "store_name": "VIADA", "address": "Mūkusalas iela 59, Rīga, LV-1004", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1066659, 56.9293356] } },
        { "type": "Feature", "id": 47, "properties": { "store_name": "VIADA", "address": "Brīvības iela 23, Jekabpīls, LV-5122", "postcode": "", "city": "Jekabpils" }, "geometry": { "type": "Point", "coordinates": [25.8847387, 56.4872954] } },
        { "type": "Feature", "id": 48, "properties": { "store_name": "VIADA", "address": "Rupniecibas iela 20d, Jelgava, LV-3008", "postcode": "", "city": "Jelgava" }, "geometry": { "type": "Point", "coordinates": [23.7119699, 56.6370273] } },
        { "type": "Feature", "id": 49, "properties": { "store_name": "VIADA", "address": "Nīcas pag.,Nīcas nov.,'Piebalgas', LV-3473", "postcode": "", "city": "Nīcas pagasts" }, "geometry": { "type": "Point", "coordinates": [21.0694174, 56.3167141] } },
        { "type": "Feature", "id": 50, "properties": { "store_name": "VIADA", "address": "Pie apvedceļa, Aiviekstes pag., Pļaviņu nov. LV-5120", "postcode": "", "city": "Aiviekstes pagasts" }, "geometry": { "type": "Point", "coordinates": [25.8187659, 56.650827] } },
        { "type": "Feature", "id": 51, "properties": { "store_name": "VIADA", "address": "Rīgas iela 116, Ogre, Ogres nov., LV-5001", "postcode": "", "city": "Ogre" }, "geometry": { "type": "Point", "coordinates": [24.5627924, 56.8191386] } },
        { "type": "Feature", "id": 52, "properties": { "store_name": "VIADA", "address": "Lubānas iela 150B, Dreiliņi, Stopiņu nov., LV-2127", "postcode": "", "city": "Dreiliņi" }, "geometry": { "type": "Point", "coordinates": [24.2413769, 56.9423811] } },
        { "type": "Feature", "id": 53, "properties": { "store_name": "VIADA", "address": "Dīči 1, Elektriķi, Codes pag., Bauskas nov., LV-3901", "postcode": "", "city": "Codes pagasts" }, "geometry": { "type": "Point", "coordinates": [24.174548, 56.412393] } },
        { "type": "Feature", "id": 54, "properties": { "store_name": "VIADA", "address": "Mazsvilpji, Ķekavas pag., Ķekavas nov., LV-2123", "postcode": "", "city": "Ķekavas pagasts" }, "geometry": { "type": "Point", "coordinates": [24.2271889, 56.8226783] } },
        { "type": "Feature", "id": 55, "properties": { "store_name": "VIADA", "address": "Maskavas iela 481, Rumbula, Stopiņu pagasts, Ropažu novads", "postcode": "", "city": "Stopiņu pagasts" }, "geometry": { "type": "Point", "coordinates": [24.2443263, 56.8828174] } },
        { "type": "Feature", "id": 56, "properties": { "store_name": "VIADA", "address": "Mūkusalas iela 78, Rīga, LV-1004", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1190476, 56.9164225] } },
        { "type": "Feature", "id": 57, "properties": { "store_name": "VIADA", "address": "Emmas iela  45, Rīga, LV-1015", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1177561, 57.0338443] } },
        { "type": "Feature", "id": 58, "properties": { "store_name": "VIADA", "address": "Krasta iela  50, Rīga, LV-1003", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1455522, 56.9335764] } },
        { "type": "Feature", "id": 59, "properties": { "store_name": "VIADA", "address": "G.Astras iela  7, Rīga, LV-1084", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1892046, 56.9577159] } },
        { "type": "Feature", "id": 60, "properties": { "store_name": "VIADA", "address": "Dārzciema iela 62, Rīga, LV-1073", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.181482, 56.938833] } },
        { "type": "Feature", "id": 61, "properties": { "store_name": "VIADA", "address": "Dzirnieku iela 1,Lidosta Rīga, Mārupes nov., LV-2167", "postcode": "", "city": "Mārupes novads" }, "geometry": { "type": "Point", "coordinates": [23.9941557, 56.9218289] } },
        { "type": "Feature", "id": 62, "properties": { "store_name": "VIADA", "address": "Cēsu iela 32, Limbaži, Limbažu nov., LV-4001", "postcode": "", "city": "Limbaži" }, "geometry": { "type": "Point", "coordinates": [24.7295134, 57.5123586] } },
        { "type": "Feature", "id": 63, "properties": { "store_name": "VIADA", "address": "Lorēni, Siguldas pag., Siguldas nov., LV-2150", "postcode": "", "city": "Siguldas pagasts" }, "geometry": { "type": "Point", "coordinates": [24.8543364, 57.154029] } },
        { "type": "Feature", "id": 64, "properties": { "store_name": "VIADA", "address": "Lietuvas šoseja 72, Jelgava, LV-3008", "postcode": "", "city": "Jelgava" }, "geometry": { "type": "Point", "coordinates": [23.7237354, 56.6242811] } },
        { "type": "Feature", "id": 65, "properties": { "store_name": "VIADA", "address": "Skorpions, Brocēni, Brocēnu nov., LV-3851", "postcode": "", "city": "Brocēni" }, "geometry": { "type": "Point", "coordinates": [22.5660158, 56.6845169] } },
        { "type": "Feature", "id": 66, "properties": { "store_name": "VIADA", "address": "Daugavpils iela 3, Rēzekne, LV-4601", "postcode": "", "city": "Rēzekne" }, "geometry": { "type": "Point", "coordinates": [27.3299376, 56.4986681] } },
        { "type": "Feature", "id": 67, "properties": { "store_name": "VIADA", "address": "Rīgas iela 52, Viļāni, Viļānu nov., LV-4650", "postcode": "", "city": "Viļāni" }, "geometry": { "type": "Point", "coordinates": [26.9336738, 56.5647675] } },
        { "type": "Feature", "id": 68, "properties": { "store_name": "VIADA", "address": "Rūpniecības iela 19, Madona, Madonas nov., LV-4801", "postcode": "", "city": "Madona" }, "geometry": { "type": "Point", "coordinates": [26.2428049, 56.8396656] } },
        { "type": "Feature", "id": 69, "properties": { "store_name": "VIADA", "address": "Valmieras iela 12, Cēsis, Cēsu nov., LV-4101", "postcode": "", "city": "Cēsis" }, "geometry": { "type": "Point", "coordinates": [25.2918056, 57.3200822] } },
        { "type": "Feature", "id": 70, "properties": { "store_name": "VIADA", "address": "Anniņmuižas bulvāris 75, Rīga, LV-1067", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0119271, 56.9590514] } },
        { "type": "Feature", "id": 71, "properties": { "store_name": "VIADA", "address": "Vidzemes iela 29, Salacgrīva, Salacgrīvas nov., LV-4033", "postcode": "", "city": "Salacgrīva" }, "geometry": { "type": "Point", "coordinates": [24.3611201, 57.7435335] } },
        { "type": "Feature", "id": 72, "properties": { "store_name": "VIADA", "address": "Laiciemkursīši, Vārzas, Skultes pag., Limbažu nov., LV-4025", "postcode": "", "city": "Vārzas" }, "geometry": { "type": "Point", "coordinates": [24.413072, 57.3820232] } },
        { "type": "Feature", "id": 73, "properties": { "store_name": "VIADA", "address": "Mazžvīguļi, Saulkrastu l. t., Saulkrastu nov., LV-2160", "postcode": "", "city": "Saulkrastu novads" }, "geometry": { "type": "Point", "coordinates": [24.4162667, 57.2604692] } },
        { "type": "Feature", "id": 74, "properties": { "store_name": "VIADA", "address": "Višķu iela  17 L, Daugavpils, LV-5410", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.5459463, 55.8946585] } },
        { "type": "Feature", "id": 75, "properties": { "store_name": "VIADA", "address": "18. novembra iela 119, Daugavpils, LV-5404", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.5471816, 55.8756626] } },
        { "type": "Feature", "id": 76, "properties": { "store_name": "VIADA", "address": "Latgales iela 42, Jēkabpils, LV-5202", "postcode": "", "city": "Jēkabpils" }, "geometry": { "type": "Point", "coordinates": [25.8743107, 56.5099498] } },
        { "type": "Feature", "id": 77, "properties": { "store_name": "VIADA", "address": "Gulbenes iela 1C, Alūksne, Alūksnes nov., LV-4301", "postcode": "", "city": "Alūksne" }, "geometry": { "type": "Point", "coordinates": [27.0423019, 57.4138477] } },
        { "type": "Feature", "id": 78, "properties": { "store_name": "VIADA", "address": "Stacijas iela 13, Balvi, Balvu nov., LV-4501", "postcode": "", "city": "Balvi" }, "geometry": { "type": "Point", "coordinates": [27.2446904, 57.1417281] } },
        { "type": "Feature", "id": 79, "properties": { "store_name": "VIADA", "address": "Baložu iela 19, Gulbene, Gulbenes nov., LV-4401", "postcode": "", "city": "Gulbene" }, "geometry": { "type": "Point", "coordinates": [26.7410621, 57.1716926] } },
        { "type": "Feature", "id": 80, "properties": { "store_name": "VIADA", "address": "Graudu iela 9, Kuldīga, Kuldīgas nov., LV-3301", "postcode": "", "city": "Kuldīga" }, "geometry": { "type": "Point", "coordinates": [21.9861152, 56.9586322] } },
        { "type": "Feature", "id": 81, "properties": { "store_name": "VIADA", "address": "Inženieru iela 2, Ventspils  LV-3601", "postcode": "", "city": "Ventspils" }, "geometry": { "type": "Point", "coordinates": [21.567408, 57.382104] } },
        { "type": "Feature", "id": 82, "properties": { "store_name": "VIADA", "address": "Kurzemes iela 59, Ventspils, LV-3602", "postcode": "", "city": "Ventspils" }, "geometry": { "type": "Point", "coordinates": [21.6144484, 57.3970068] } },
        { "type": "Feature", "id": 83, "properties": { "store_name": "VIADA", "address": "Brīvnieki, Kauguru pag., Beverīnas nov., LV-4224", "postcode": "", "city": "Kauguru pagasts" }, "geometry": { "type": "Point", "coordinates": [25.4628228, 57.5079243] } },
        { "type": "Feature", "id": 84, "properties": { "store_name": "VIADA", "address": "Gaigalas iela  19a, Rīga, LV-1016", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0468128, 57.0293046] } },
        { "type": "Feature", "id": 85, "properties": { "store_name": "VIADA", "address": "Džutas iela 16, Rīga, LV-1006", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1836808, 56.9818953] } },
        { "type": "Feature", "id": 86, "properties": { "store_name": "VIADA", "address": "Ganību iela 180, Liepāja, LV-3407", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0155197, 56.485341] } },
        { "type": "Feature", "id": 87, "properties": { "store_name": "VIADA", "address": "Kapsētas iela 24, Liepāja, LV-3401", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.006508, 56.520345] } },
        { "type": "Feature", "id": 88, "properties": { "store_name": "VIADA", "address": "Apūzes 47, Rīga, LV-1029", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0214953, 56.9410702] } },
        { "type": "Feature", "id": 89, "properties": { "store_name": "VIADA", "address": "Zemgales iela 25, Tukums, Tukuma nov., LV-3101", "postcode": "", "city": "Tukums" }, "geometry": { "type": "Point", "coordinates": [23.1770536, 56.9573905] } },
        { "type": "Feature", "id": 90, "properties": { "store_name": "VIADA", "address": "Brikšķu iela 2, Jūrmala, LV-2016", "postcode": "", "city": "Jūrmala" }, "geometry": { "type": "Point", "coordinates": [23.6211624, 56.9605675] } },
        { "type": "Feature", "id": 91, "properties": { "store_name": "VIADA", "address": "Kārļa Ulmaņa gatve 135, Rīga, LV-1029", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [23.9997797, 56.9384762] } },
        { "type": "Feature", "id": 92, "properties": { "store_name": "VIADA", "address": "Krūzes iela 53, Rīga, LV-1002", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0625208, 56.9285889] } },
        { "type": "Feature", "id": 93, "properties": { "store_name": "VIADA", "address": "Dambja iela 11, Rīga, LV-1005", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.129766, 56.988622] } },
        { "type": "Feature", "id": 94, "properties": { "store_name": "VIADA", "address": "Maskavas iela 293A, Rīga, LV-1063", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.175417, 56.915899] } },
        { "type": "Feature", "id": 95, "properties": { "store_name": "VIADA", "address": "Juglas iela 16C, Rīga, LV-1064", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2553192, 56.9764387] } },
        { "type": "Feature", "id": 96, "properties": { "store_name": "VIADA", "address": "Ziepniekkalna 20, Rīga, LV-1004", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1275761, 56.9098604] } },
        { "type": "Feature", "id": 97, "properties": { "store_name": "VIADA", "address": "Ziemeri, Sunīši, Garkalnes nov, Lv-2137", "postcode": "", "city": "Garkalnes novads" }, "geometry": { "type": "Point", "coordinates": [24.3413224, 56.973973] } },
        { "type": "Feature", "id": 98, "properties": { "store_name": "VIADA", "address": "Talsu iela 21, Dundaga, Dundagas pag., Dundag. Nov., LV-3270", "postcode": "", "city": "Dundaga" }, "geometry": { "type": "Point", "coordinates": [22.3519151, 57.5032933] } },
        { "type": "Feature", "id": 99, "properties": { "store_name": "VIADA", "address": "Vienības Gatve 162a, Rīga, LV-1058", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0837773, 56.9059905] } },
        { "type": "Feature", "id": 100, "properties": { "store_name": "VIADA", "address": "Cietokšņa ielā 82, Daugavpils, LV-5401", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.5055306, 55.8788762] } },
        { "type": "Feature", "id": 101, "properties": { "store_name": "VIADA", "address": "Vizbuļu iela 8a, Daugavpils, LV-5401", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.4835572, 55.914441] } },
        { "type": "Feature", "id": 102, "properties": { "store_name": "VIADA", "address": "Degvielas Uzpildes Stacija Ilūkstes nov., Pilskalnes pag., LV-5447", "postcode": "", "city": "Pilskalnes pagasts" }, "geometry": { "type": "Point", "coordinates": [26.221847, 55.9832224] } },
        { "type": "Feature", "id": 103, "properties": { "store_name": "VIADA", "address": "Vasarnieku iela 6, Krāslava, LV-5601", "postcode": "", "city": "Krāslava" }, "geometry": { "type": "Point", "coordinates": [27.1795068, 55.9058551] } },
        { "type": "Feature", "id": 104, "properties": { "store_name": "VIADA", "address": "18. novembra iela 335D, Daugavpils, LV-5413", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.6045428, 55.8952841] } },
        { "type": "Feature", "id": 105, "properties": { "store_name": "VIADA", "address": "Varoņu iela 6, Jēkabpils, LV-5202", "postcode": "", "city": "Jēkabpils" }, "geometry": { "type": "Point", "coordinates": [25.8664134, 56.5147907] } },
        { "type": "Feature", "id": 106, "properties": { "store_name": "VIADA", "address": "s.Tēviņi, Ozolaines pagasts, Rēzeknes nov., LV-4601", "postcode": "", "city": "Ozolaines pagasts" }, "geometry": { "type": "Point", "coordinates": [27.3027849, 56.486962] } },
        { "type": "Feature", "id": 107, "properties": { "store_name": "VIADA", "address": "Rīgas iela 8, Preiļi, Preiļu nov., LV-5301", "postcode": "", "city": "Preiļi" }, "geometry": { "type": "Point", "coordinates": [26.7061945, 56.298035] } },
        { "type": "Feature", "id": 108, "properties": { "store_name": "VIADA", "address": "Maltas uzpildes stacija, Mostovaja, Lūznavas pag., Rēzeknes nov., LV-4630", "postcode": "", "city": "Lūznavas pagasts" }, "geometry": { "type": "Point", "coordinates": [27.1572924, 56.3507084] } },
        { "type": "Feature", "id": 109, "properties": { "store_name": "VIADA", "address": "Šosejas iela 5A, Kārsava, Kārsavas nov., LV-5717", "postcode": "", "city": "Kārsava" }, "geometry": { "type": "Point", "coordinates": [27.6881323, 56.7750919] } },
        { "type": "Feature", "id": 110, "properties": { "store_name": "VIADA", "address": "Griškānu pagasts, Rēzeknes nov., LV-4601", "postcode": "", "city": "Griškānu pagasts" }, "geometry": { "type": "Point", "coordinates": [27.312526, 56.4887429] } },
        { "type": "Feature", "id": 111, "properties": { "store_name": "VIADA", "address": "Latgales iela 22A, Ludza, Ludzas nov., LV-5701", "postcode": "", "city": "Ludza" }, "geometry": { "type": "Point", "coordinates": [27.7019678, 56.5388202] } },
        { "type": "Feature", "id": 112, "properties": { "store_name": "VIADA", "address": "DUS Glāžšķūnis, Glāžšķūnis, Rembates pag., Ķeguma nov., LV-5016", "postcode": "", "city": "Rembates pagasts" }, "geometry": { "type": "Point", "coordinates": [24.868355, 56.7989431] } },
        { "type": "Feature", "id": 113, "properties": { "store_name": "VIADA", "address": "Mežezera iela 1, Aizupes, Tīnūžu pag., Ikšķiles nov., LV-5001", "postcode": "", "city": "Aizupes" }, "geometry": { "type": "Point", "coordinates": [24.5994947, 56.8249914] } },
        { "type": "Feature", "id": 114, "properties": { "store_name": "VIADA", "address": "Eskāda, Audriņi, Audriņu pag., Rēzeknes nov., LV-4611", "postcode": "", "city": "Audriņu pagasts" }, "geometry": { "type": "Point", "coordinates": [27.2456494, 56.5876045] } },
        { "type": "Feature", "id": 115, "properties": { "store_name": "VIADA", "address": "Jaunceltnes iela 16, Aizkraukle, Aizkraukles nov., LV5101", "postcode": "", "city": "Aizkraukle" }, "geometry": { "type": "Point", "coordinates": [25.249459, 56.611814] } },
        { "type": "Feature", "id": 116, "properties": { "store_name": "VIADA", "address": "Krasta iela 78, Rīga, LV-1019", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1615251, 56.9260133] } },
        { "type": "Feature", "id": 117, "properties": { "store_name": "VIADA", "address": "Lietuvas šoseja 2B, Jelgava, LV-3001", "postcode": "", "city": "Jelgava" }, "geometry": { "type": "Point", "coordinates": [23.7275999, 56.6363149] } },
        { "type": "Feature", "id": 118, "properties": { "store_name": "VIADA", "address": "Ūsiņš, Suntažu pag., Ogres nov., LV-5060", "postcode": "", "city": "Suntažu pagasts" }, "geometry": { "type": "Point", "coordinates": [24.9517101, 56.8875617] } },
        { "type": "Feature", "id": 119, "properties": { "store_name": "VIADA", "address": "Mellupi, Mellupi, Ķekavas pag., Ķekavas nov., LV-2113", "postcode": "", "city": "Ķekavas pagasts" }, "geometry": { "type": "Point", "coordinates": [24.0901451, 56.7376971] } },
        { "type": "Feature", "id": 120, "properties": { "store_name": "VIADA", "address": "Rīgas iela 56, Jelgava, LV-3004", "postcode": "", "city": "Jelgava" }, "geometry": { "type": "Point", "coordinates": [23.7729013, 56.6753444] } },
        { "type": "Feature", "id": 121, "properties": { "store_name": "Tabakas Nams", "address": "Rītupe ielas 8/12", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1097857, 56.9592119] } },
        { "type": "Feature", "id": 122, "properties": { "store_name": "Tabakas Nams", "address": "Dubultu prospekts 20", "postcode": "", "city": "Jūrmala" }, "geometry": { "type": "Point", "coordinates": [23.7691665, 56.9676509] } },
        { "type": "Feature", "id": 123, "properties": { "store_name": "Tabakas Nams", "address": "Paula Lejiņa iela 2a, LV-1029", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0143436, 56.9459532] } },
        { "type": "Feature", "id": 124, "properties": { "store_name": "Tabakas Nams", "address": "Gobas iela 37, LV-1016", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0546212, 57.0304201] } },
        { "type": "Feature", "id": 125, "properties": { "store_name": "Tabakas Nams", "address": "Zemgales iela 8, LV-2114", "postcode": "", "city": "Olaine" }, "geometry": { "type": "Point", "coordinates": [23.9399792, 56.7868247] } },
        { "type": "Feature", "id": 126, "properties": { "store_name": "Tabakas Nams", "address": "Vaidelotes iela 13-47, LV-1055", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0516442, 56.9666659] } },
        { "type": "Feature", "id": 127, "properties": { "store_name": "Tabakas Nams", "address": "Atpūtas iela 2a, LV-1002", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.059138, 56.9368987] } },
        { "type": "Feature", "id": 128, "properties": { "store_name": "Tabakas Nams", "address": "Kandavas iela 4a, LV-5400", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.5148449, 55.8729089] } },
        { "type": "Feature", "id": 129, "properties": { "store_name": "Vynoteka", "address": "Vaidavas iela 9B", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1769781, 56.961992] } },
        { "type": "Feature", "id": 130, "properties": { "store_name": "Vynoteka", "address": "Kalnciema iela 41", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.062648, 56.942362] } },
        { "type": "Feature", "id": 131, "properties": { "store_name": "Vynoteka", "address": "Stirnu iela 26", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1856467, 56.9586544] } },
        { "type": "Feature", "id": 132, "properties": { "store_name": "Vynoteka", "address": "Raiņa iela 112B", "postcode": "", "city": "Jūrmala" }, "geometry": { "type": "Point", "coordinates": [23.6108736, 56.9598141] } },
        { "type": "Feature", "id": 133, "properties": { "store_name": "Vynoteka", "address": "Strēlnieku iela 2", "postcode": "", "city": "Sigulda" }, "geometry": { "type": "Point", "coordinates": [24.8443144, 57.1435316] } },
        { "type": "Feature", "id": 134, "properties": { "store_name": "Vynoteka", "address": "Dižozolu iela 11", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.096666, 56.9032842] } },
        { "type": "Feature", "id": 135, "properties": { "store_name": "Vynoteka", "address": "Jomas iela 46", "postcode": "", "city": "Jūrmala" }, "geometry": { "type": "Point", "coordinates": [23.8001529, 56.9727067] } },
        { "type": "Feature", "id": 136, "properties": { "store_name": "Vynoteka", "address": "Liepu iela 1", "postcode": "", "city": "Bauska" }, "geometry": { "type": "Point", "coordinates": [24.194181, 56.4064593] } },
        { "type": "Feature", "id": 137, "properties": { "store_name": "Vynoteka", "address": "Lietuvas iela 44", "postcode": "", "city": "Eleja" }, "geometry": { "type": "Point", "coordinates": [23.6811362, 56.4086074] } },
        { "type": "Feature", "id": 138, "properties": { "store_name": "Vynoteka", "address": "Krasta iela 70A", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1578161, 56.9283027] } },
        { "type": "Feature", "id": 139, "properties": { "store_name": "Vynoteka", "address": "Rīgas gatve 5a", "postcode": "", "city": "Ādaži" }, "geometry": { "type": "Point", "coordinates": [24.3202214, 57.0704966] } },
        { "type": "Feature", "id": 140, "properties": { "store_name": "Vynoteka", "address": "Mārupes iela 2", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0713615, 56.9349744] } },
        { "type": "Feature", "id": 141, "properties": { "store_name": "Vynoteka", "address": "Centra laukums 4", "postcode": "", "city": "Ezere" }, "geometry": { "type": "Point", "coordinates": [22.3623559, 56.4087025] } },
        { "type": "Feature", "id": 142, "properties": { "store_name": "Vynoteka", "address": "Biķernieku iela 21", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1723512, 56.9713863] } },
        { "type": "Feature", "id": 143, "properties": { "store_name": "Vynoteka", "address": "Brīvības gatve 402C", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2271904, 56.9873703] } },
        { "type": "Feature", "id": 144, "properties": { "store_name": "Vynoteka", "address": "Grenctāles robežkontroles punkts", "postcode": "", "city": "Brunavas pagasts" }, "geometry": { "type": "Point", "coordinates": [24.3431608, 56.3121831] } },
        { "type": "Feature", "id": 145, "properties": { "store_name": "Vynoteka", "address": "Grostonas iela 1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1199184, 56.9642695] } },
        { "type": "Feature", "id": 146, "properties": { "store_name": "Narvesen", "address": "Dzelzavas iela 44 (pie purvciema tirgus)", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1810219, 56.958258] } },
        { "type": "Feature", "id": 147, "properties": { "store_name": "Narvesen", "address": "Brīvības gatve 430", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.239819, 56.988874] } },
        { "type": "Feature", "id": 148, "properties": { "store_name": "Narvesen", "address": "Kr.Barona 125 - Pērnavas st.", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1492669, 56.9659699] } },
        { "type": "Feature", "id": 149, "properties": { "store_name": "Narvesen", "address": "Gogoļa - Lāčplēša 110", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1302712, 56.9423191] } },
        { "type": "Feature", "id": 150, "properties": { "store_name": "Narvesen", "address": "Slokas 1- A.Grīna bulvāris", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0818742, 56.941893] } },
        { "type": "Feature", "id": 151, "properties": { "store_name": "Narvesen", "address": "Vienības gatve 32", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0910366, 56.927764] } },
        { "type": "Feature", "id": 152, "properties": { "store_name": "Narvesen", "address": "Dzelzavas 72", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1905947, 56.956241] } },
        { "type": "Feature", "id": 153, "properties": { "store_name": "Narvesen", "address": "Brīvības 197", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1587269, 56.9710386] } },
        { "type": "Feature", "id": 154, "properties": { "store_name": "Narvesen", "address": "Ilūkstes-Deglava st.", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.19629, 56.9477599] } },
        { "type": "Feature", "id": 155, "properties": { "store_name": "Narvesen", "address": "Maskavas 185-Lubānas", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1589016, 56.9344404] } },
        { "type": "Feature", "id": 156, "properties": { "store_name": "Narvesen", "address": "Tilta 11", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1293002, 56.9959768] } },
        { "type": "Feature", "id": 157, "properties": { "store_name": "Narvesen", "address": "Dammes 4", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0157554, 56.9617434] } },
        { "type": "Feature", "id": 158, "properties": { "store_name": "Narvesen", "address": "Anniņmuižas - Dammes", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.01233, 56.9599] } },
        { "type": "Feature", "id": 159, "properties": { "store_name": "Narvesen", "address": "Stacija Imanta", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [23.998573, 56.9502455] } },
        { "type": "Feature", "id": 160, "properties": { "store_name": "Narvesen", "address": "Kastrānes ielā 5", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1772606, 56.9673962] } },
        { "type": "Feature", "id": 161, "properties": { "store_name": "Narvesen", "address": "Miera-Hospitāļu 1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1354237, 56.9690237] } },
        { "type": "Feature", "id": 162, "properties": { "store_name": "Narvesen", "address": "Rušonu 28-Prūšu", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1856886, 56.9150523] } },
        { "type": "Feature", "id": 163, "properties": { "store_name": "Narvesen", "address": "Brīvības 310-teika", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1857562, 56.9788396] } },
        { "type": "Feature", "id": 164, "properties": { "store_name": "Narvesen", "address": "A. Deglava ielā 100", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1867596, 56.9487307] } },
        { "type": "Feature", "id": 165, "properties": { "store_name": "Narvesen", "address": "Gobas 14 (Universālveikals)", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0481502, 57.0332359] } },
        { "type": "Feature", "id": 166, "properties": { "store_name": "Narvesen", "address": "Brīvības 399 - Mārkalnes", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2286943, 56.988403] } },
        { "type": "Feature", "id": 167, "properties": { "store_name": "Narvesen", "address": "Audeju 16, t/c Galerija Centrs", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.112301, 56.9480656] } },
        { "type": "Feature", "id": 168, "properties": { "store_name": "Narvesen", "address": "Krasta 46, t/c Mols", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.14273, 56.93404] } },
        { "type": "Feature", "id": 169, "properties": { "store_name": "Narvesen", "address": "Kr. Barona 78", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.132895, 56.9567481] } },
        { "type": "Feature", "id": 170, "properties": { "store_name": "Narvesen", "address": "Stacijas 2, t/c Origo, Tornis 1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.103913, 56.951607] } },
        { "type": "Feature", "id": 171, "properties": { "store_name": "Narvesen", "address": "Stacijas 2, t/c Origo, Tornis 2", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1030459, 56.9517445] } },
        { "type": "Feature", "id": 172, "properties": { "store_name": "Narvesen", "address": "Brivibas 372, Alfa 2", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2037854, 56.9825626] } },
        { "type": "Feature", "id": 173, "properties": { "store_name": "Narvesen", "address": "Kokneses pr. 38", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1577355, 57.0048451] } },
        { "type": "Feature", "id": 174, "properties": { "store_name": "Narvesen", "address": "Brīvības 372, Alfa 3", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2037854, 56.9825626] } },
        { "type": "Feature", "id": 175, "properties": { "store_name": "Narvesen", "address": "Saharova 1a", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2008567, 56.9331287] } },
        { "type": "Feature", "id": 176, "properties": { "store_name": "Narvesen", "address": "Maskavas 357, t/c Dole", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.191285, 56.904875] } },
        { "type": "Feature", "id": 177, "properties": { "store_name": "Narvesen", "address": "Elizabetes iela 91/93", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.123069, 56.9488092] } },
        { "type": "Feature", "id": 178, "properties": { "store_name": "Narvesen", "address": "Kr. Barona 11", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1223978, 56.9522163] } },
        { "type": "Feature", "id": 179, "properties": { "store_name": "Narvesen", "address": "Brīvības 31", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1374555, 56.9633099] } },
        { "type": "Feature", "id": 180, "properties": { "store_name": "Narvesen", "address": "Murjāņu 58", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2545707, 56.9778239] } },
        { "type": "Feature", "id": 181, "properties": { "store_name": "Narvesen", "address": "Prāgas iela 1 (NB 1020)", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1146827, 56.9450497] } },
        { "type": "Feature", "id": 182, "properties": { "store_name": "Narvesen", "address": "Jaunmoku iela 21 (t/c Spice)", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0381745, 56.9285901] } },
        { "type": "Feature", "id": 183, "properties": { "store_name": "Narvesen", "address": "Āzenes 5, t/c Olimpija", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.082394, 56.9498275] } },
        { "type": "Feature", "id": 184, "properties": { "store_name": "Narvesen", "address": "Stacijas laukums 4", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1206123, 56.9476755] } },
        { "type": "Feature", "id": 185, "properties": { "store_name": "Narvesen", "address": "Ieriku 3, t/c Domina", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1629748, 56.966449] } },
        { "type": "Feature", "id": 186, "properties": { "store_name": "Narvesen", "address": "Brīvības 23", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1160549, 56.9537718] } },
        { "type": "Feature", "id": 187, "properties": { "store_name": "Narvesen", "address": "Barona 46, t/c Barona centrs", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1276019, 56.9540281] } },
        { "type": "Feature", "id": 188, "properties": { "store_name": "Narvesen", "address": "Kaļķu 28, Hotel de Rome", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1118295, 56.9501286] } },
        { "type": "Feature", "id": 189, "properties": { "store_name": "Narvesen", "address": "Rīgas Lidosta, Mārupes pag., Rīgas raj.", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [23.9733129, 56.9226554] } },
        { "type": "Feature", "id": 190, "properties": { "store_name": "Narvesen", "address": "Rīgas Lidosta, Mārupes pag., Rīgas raj.", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [23.9733129, 56.9226554] } },
        { "type": "Feature", "id": 191, "properties": { "store_name": "Narvesen", "address": "Gogoļa-Gaiziņa 1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1204686, 56.9444431] } },
        { "type": "Feature", "id": 192, "properties": { "store_name": "Narvesen", "address": "Škūnu iela 8/Monētu 2", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1074371, 56.948875] } },
        { "type": "Feature", "id": 193, "properties": { "store_name": "Narvesen", "address": "Raiņa bulv. 33", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1189494, 56.9479924] } },
        { "type": "Feature", "id": 194, "properties": { "store_name": "Narvesen", "address": "Kaļķu iela 2", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1076126, 56.9480359] } },
        { "type": "Feature", "id": 195, "properties": { "store_name": "Narvesen", "address": "Rīgas 11a", "postcode": "", "city": "Jelgava" }, "geometry": { "type": "Point", "coordinates": [23.7462381, 56.6618909] } },
        { "type": "Feature", "id": 196, "properties": { "store_name": "Narvesen", "address": "Slokas 115, Maxima", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0377771, 56.9604107] } },
        { "type": "Feature", "id": 197, "properties": { "store_name": "Narvesen", "address": "Skanstes 21,  ARĒNA RĪGA", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1213099, 56.9679331] } },
        { "type": "Feature", "id": 198, "properties": { "store_name": "Narvesen", "address": "Driksas 4, t/c Pilsētas pasāža", "postcode": "", "city": "Jelgava" }, "geometry": { "type": "Point", "coordinates": [23.7236596, 56.6510669] } },
        { "type": "Feature", "id": 199, "properties": { "store_name": "Narvesen", "address": "Lidosta Rīga, Mārupes pag., Mārupes nov.", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [23.9822728, 56.9226831] } },
        { "type": "Feature", "id": 200, "properties": { "store_name": "Narvesen", "address": "Ūnijas 52", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1773183, 56.9644366] } },
        { "type": "Feature", "id": 201, "properties": { "store_name": "Narvesen", "address": "Saharova iela 14", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2042173, 56.9371345] } },
        { "type": "Feature", "id": 202, "properties": { "store_name": "Narvesen", "address": "Strelnieku 1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.106061, 56.958767] } },
        { "type": "Feature", "id": 203, "properties": { "store_name": "Narvesen", "address": "Valdemāra 112, RIMI", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1366443, 56.9743423] } },
        { "type": "Feature", "id": 204, "properties": { "store_name": "Narvesen", "address": "Brīvības 32, Sakta", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1153172, 56.9521535] } },
        { "type": "Feature", "id": 205, "properties": { "store_name": "Narvesen", "address": "Stirnu iela 26, RIMI", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1856217, 56.9582817] } },
        { "type": "Feature", "id": 206, "properties": { "store_name": "Narvesen", "address": "Jomas iela 46", "postcode": "", "city": "Jūrmala" }, "geometry": { "type": "Point", "coordinates": [23.8001529, 56.9727067] } },
        { "type": "Feature", "id": 207, "properties": { "store_name": "Narvesen", "address": "Lidosta Rīga 10k-1,Mārupes novads", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [23.9733129, 56.9226554] } },
        { "type": "Feature", "id": 208, "properties": { "store_name": "Narvesen", "address": "Starptautiska Lidosta Rīga (Muitas zona)", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [23.9733129, 56.9226554] } },
        { "type": "Feature", "id": 209, "properties": { "store_name": "Narvesen", "address": "Avotu/Čaka 22", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1273229, 56.9512668] } },
        { "type": "Feature", "id": 210, "properties": { "store_name": "Narvesen", "address": "Ausekļa iela 6, dzelzc. stacija", "postcode": "", "city": "Sigulda" }, "geometry": { "type": "Point", "coordinates": [24.8539979, 57.1531928] } },
        { "type": "Feature", "id": 211, "properties": { "store_name": "Narvesen", "address": "Lidosta Rīga  Mārupes nov. (2.st)", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [23.9822728, 56.9226831] } },
        { "type": "Feature", "id": 212, "properties": { "store_name": "Narvesen", "address": "Rīgas 4, t/c Valleta", "postcode": "", "city": "Valmiera" }, "geometry": { "type": "Point", "coordinates": [24.1056221, 56.9676941] } },
        { "type": "Feature", "id": 213, "properties": { "store_name": "Narvesen", "address": "Jomas 60", "postcode": "", "city": "Jūrmala" }, "geometry": { "type": "Point", "coordinates": [23.8037314, 56.9733374] } },
        { "type": "Feature", "id": 214, "properties": { "store_name": "Narvesen", "address": "Kuldīgas 5, autoosta", "postcode": "", "city": "Ventspils" }, "geometry": { "type": "Point", "coordinates": [21.5695321, 57.3947423] } },
        { "type": "Feature", "id": 215, "properties": { "store_name": "Narvesen", "address": "Liela iela 13, t/c Kurzeme", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0109044, 56.5075082] } },
        { "type": "Feature", "id": 216, "properties": { "store_name": "Narvesen", "address": "Mūkusalas 71, t/c Rīga Plaza", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1035358, 56.9263822] } },
        { "type": "Feature", "id": 217, "properties": { "store_name": "Narvesen", "address": "Vienības gatve 45, bērnu slimnīca", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0862499, 56.9223787] } },
        { "type": "Feature", "id": 218, "properties": { "store_name": "Narvesen", "address": "Dzirnavu iela 67", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1207463, 56.954564] } },
        { "type": "Feature", "id": 219, "properties": { "store_name": "Narvesen", "address": "Miera 2", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1295209, 56.9603155] } },
        { "type": "Feature", "id": 220, "properties": { "store_name": "Narvesen", "address": "Valdeķu ielā 62A", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0935811, 56.9013519] } },
        { "type": "Feature", "id": 221, "properties": { "store_name": "Narvesen", "address": "Krasta 68", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1142396, 56.941923] } },
        { "type": "Feature", "id": 222, "properties": { "store_name": "Narvesen", "address": "Talsu šoseja 66", "postcode": "", "city": "Jūrmala" }, "geometry": { "type": "Point", "coordinates": [23.6040943, 56.9640125] } },
        { "type": "Feature", "id": 223, "properties": { "store_name": "Narvesen", "address": "Maskavas 427b", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2106221, 56.8974825] } },
        { "type": "Feature", "id": 224, "properties": { "store_name": "Narvesen", "address": "Dambja 10 (Duntes)", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1302518, 56.9883691] } },
        { "type": "Feature", "id": 225, "properties": { "store_name": "Narvesen", "address": "Viestura21", "postcode": "", "city": "Jūrmala" }, "geometry": { "type": "Point", "coordinates": [23.7737546, 56.9684476] } },
        { "type": "Feature", "id": 226, "properties": { "store_name": "Narvesen", "address": "Kuldīgas 1, (Daugavgrīvas)", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0691187, 56.9539141] } },
        { "type": "Feature", "id": 227, "properties": { "store_name": "Narvesen", "address": "Upesgrīvas 1", "postcode": "", "city": "Mārupe" }, "geometry": { "type": "Point", "coordinates": [24.0393146, 56.9262396] } },
        { "type": "Feature", "id": 228, "properties": { "store_name": "Narvesen", "address": "Vienības gatve 144", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0872004, 56.9101617] } },
        { "type": "Feature", "id": 229, "properties": { "store_name": "Narvesen", "address": "Lucavsalas 1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1107024, 56.9249799] } },
        { "type": "Feature", "id": 230, "properties": { "store_name": "Narvesen", "address": "Ulmana gatve 84", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0519766, 56.9282173] } },
        { "type": "Feature", "id": 231, "properties": { "store_name": "Narvesen", "address": "Loka magistrāle 2a", "postcode": "", "city": "Jelgava" }, "geometry": { "type": "Point", "coordinates": [23.7617981, 56.6707839] } },
        { "type": "Feature", "id": 232, "properties": { "store_name": "Narvesen", "address": "Iecavas pag. Krustini", "postcode": "", "city": "Iecava" }, "geometry": { "type": "Point", "coordinates": [24.2067557, 56.5946659] } },
        { "type": "Feature", "id": 233, "properties": { "store_name": "Narvesen", "address": "Katoļu 4", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1380736, 56.940759] } },
        { "type": "Feature", "id": 234, "properties": { "store_name": "Narvesen", "address": "Lubānas ielā 64", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1844726, 56.9307361] } },
        { "type": "Feature", "id": 235, "properties": { "store_name": "Narvesen", "address": "Elejas 1", "postcode": "", "city": "Bauska" }, "geometry": { "type": "Point", "coordinates": [24.1773157, 56.3992743] } },
        { "type": "Feature", "id": 236, "properties": { "store_name": "Narvesen", "address": "Dundagas 1", "postcode": "", "city": "Talsi" }, "geometry": { "type": "Point", "coordinates": [22.5820411, 57.2433398] } },
        { "type": "Feature", "id": 237, "properties": { "store_name": "Narvesen", "address": "Kurzemes prospekts 15a", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0356923, 56.9619] } },
        { "type": "Feature", "id": 238, "properties": { "store_name": "Narvesen", "address": "Sūru iel 3", "postcode": "", "city": "Kuldīga" }, "geometry": { "type": "Point", "coordinates": [21.9572304, 56.9666248] } },
        { "type": "Feature", "id": 239, "properties": { "store_name": "Narvesen", "address": "Apvedceļš 5", "postcode": "", "city": "Saldus" }, "geometry": { "type": "Point", "coordinates": [22.4762622, 56.6834355] } },
        { "type": "Feature", "id": 240, "properties": { "store_name": "Narvesen", "address": "Aspazijas bulvāris 32", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1142959, 56.9475257] } },
        { "type": "Feature", "id": 241, "properties": { "store_name": "Narvesen", "address": "Jaunā Ostmala 3/5", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0182161, 56.5150104] } },
        { "type": "Feature", "id": 242, "properties": { "store_name": "Narvesen", "address": "Lidoņu iela 30", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0581867, 56.9692825] } },
        { "type": "Feature", "id": 243, "properties": { "store_name": "Narvesen", "address": "Stacijas iela 1", "postcode": "", "city": "Valmiera" }, "geometry": { "type": "Point", "coordinates": [25.4262843, 57.5344716] } },
        { "type": "Feature", "id": 244, "properties": { "store_name": "Narvesen", "address": "Lielirbes iela 29, Spice 2. stāvs", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0363368, 56.9299363] } },
        { "type": "Feature", "id": 245, "properties": { "store_name": "Narvesen", "address": "K.Ulmaņa gatve 88A", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0248425, 56.9291181] } },
        { "type": "Feature", "id": 246, "properties": { "store_name": "Narvesen", "address": "Nīcgales iela 46a", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1716696, 56.9448049] } },
        { "type": "Feature", "id": 247, "properties": { "store_name": "Narvesen", "address": "Talejas 1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1701011, 56.9913494] } },
        { "type": "Feature", "id": 248, "properties": { "store_name": "Narvesen", "address": "A. Čaka iela 102", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.141085, 56.958528] } },
        { "type": "Feature", "id": 249, "properties": { "store_name": "Narvesen", "address": "TC Damme ,Kurzemes prospekts  1A", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0385579, 56.953976] } },
        { "type": "Feature", "id": 250, "properties": { "store_name": "Narvesen", "address": "Tirgus iela 1", "postcode": "", "city": "Saldus" }, "geometry": { "type": "Point", "coordinates": [22.4938228, 56.6676226] } },
        { "type": "Feature", "id": 251, "properties": { "store_name": "Narvesen", "address": "Kuršu laukums 4a", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0119556, 56.5051075] } },
        { "type": "Feature", "id": 252, "properties": { "store_name": "Narvesen", "address": "Brīvības 121", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1369965, 56.9632147] } },
        { "type": "Feature", "id": 253, "properties": { "store_name": "Narvesen", "address": "Pilsoņu iela 13, Rīga", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0661868, 56.9324362] } },
        { "type": "Feature", "id": 254, "properties": { "store_name": "Narvesen", "address": "Brīvības 66 - 601", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.123004, 56.9570257] } },
        { "type": "Feature", "id": 255, "properties": { "store_name": "Narvesen", "address": "Stacijas laukums 5", "postcode": "", "city": "Cēsis" }, "geometry": { "type": "Point", "coordinates": [25.2816498, 57.3130027] } },
        { "type": "Feature", "id": 256, "properties": { "store_name": "Narvesen", "address": "Grēcinieku iela 11a", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1093494, 56.9472195] } },
        { "type": "Feature", "id": 257, "properties": { "store_name": "Narvesen", "address": "Vaļņu iela 2", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1091884, 56.9510705] } },
        { "type": "Feature", "id": 258, "properties": { "store_name": "Narvesen", "address": "Merķeļa iela 2, Rīga", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1189387, 56.9497711] } },
        { "type": "Feature", "id": 259, "properties": { "store_name": "Narvesen", "address": "Rīgas gatve 5a", "postcode": "", "city": "Ādaži" }, "geometry": { "type": "Point", "coordinates": [24.3202214, 57.0704966] } },
        { "type": "Feature", "id": 260, "properties": { "store_name": "Narvesen", "address": "Mazā Nometņu iela 27, tc Mellene", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0721016, 56.9352946] } },
        { "type": "Feature", "id": 261, "properties": { "store_name": "Narvesen", "address": "Miera iela 45", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1333838, 56.966339] } },
        { "type": "Feature", "id": 262, "properties": { "store_name": "Narvesen", "address": "Krišjāņa Valdemāra ielā 21", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1137454, 56.9568091] } },
        { "type": "Feature", "id": 263, "properties": { "store_name": "Narvesen", "address": "Ieriķu 3, t/c Domina", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1629748, 56.966449] } },
        { "type": "Feature", "id": 264, "properties": { "store_name": "Narvesen", "address": "Hipokrāta iela 2 (Gaiļezera slim.)", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2415254, 56.9677159] } },
        { "type": "Feature", "id": 265, "properties": { "store_name": "Narvesen", "address": "Skolas iela 7 (ARS)", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1168328, 56.9569243] } },
        { "type": "Feature", "id": 266, "properties": { "store_name": "Narvesen", "address": "Barona iela 97", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1414243, 56.961503] } },
        { "type": "Feature", "id": 267, "properties": { "store_name": "Narvesen", "address": "Maskavas iela 257 (Akropole)", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1754036, 56.9239152] } },
        { "type": "Feature", "id": 268, "properties": { "store_name": "Narvesen", "address": "Tomsona iela 25/87A", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1252131, 56.9658438] } },
        { "type": "Feature", "id": 269, "properties": { "store_name": "Narvesen", "address": "Gustava Zemgala Gatve 74", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1653917, 56.9750827] } },
        { "type": "Feature", "id": 270, "properties": { "store_name": "Narvesen", "address": "Grostonas iela 1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1199184, 56.9642695] } },
        { "type": "Feature", "id": 271, "properties": { "store_name": "Narvesen", "address": "Valdeko, Valmieras pag., Burtnieku n.", "postcode": "", "city": "Valmiermuiža" }, "geometry": { "type": "Point", "coordinates": [24.1835421, 56.9706894] } },
        { "type": "Feature", "id": 272, "properties": { "store_name": "Narvesen", "address": "Prāgas iela 1 (NB 1141)", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1146827, 56.9450497] } },
        { "type": "Feature", "id": 273, "properties": { "store_name": "Narvesen", "address": "Dzelzavas iela 60", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1840064, 56.9574964] } },
        { "type": "Feature", "id": 274, "properties": { "store_name": "Narvesen", "address": "Brīvības 90-128", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1302319, 56.9599303] } },
        { "type": "Feature", "id": 275, "properties": { "store_name": "Narvesen", "address": "Zemgales iela 1A", "postcode": "", "city": "Olaine" }, "geometry": { "type": "Point", "coordinates": [23.9469732, 56.791469] } },
        { "type": "Feature", "id": 276, "properties": { "store_name": "Narvesen", "address": "Centrāltirgus iela 3", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1148429, 56.9436751] } },
        { "type": "Feature", "id": 277, "properties": { "store_name": "Narvesen", "address": "Slokas iela 14", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0748665, 56.9455147] } },
        { "type": "Feature", "id": 278, "properties": { "store_name": "Narvesen", "address": "Maskavas iela 257 (Akropole 2)", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1754036, 56.9239152] } },
        { "type": "Feature", "id": 279, "properties": { "store_name": "Narvesen", "address": "Vienības gatve 194A", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0792465, 56.8960409] } },
        { "type": "Feature", "id": 280, "properties": { "store_name": "Narvesen", "address": "Pērnavas 55", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1542915, 56.9531828] } },
        { "type": "Feature", "id": 281, "properties": { "store_name": "Narvesen", "address": "TC Šokolāde Strēlnieku iela 2", "postcode": "", "city": "Sigulda" }, "geometry": { "type": "Point", "coordinates": [24.8447222, 57.1433333] } },
        { "type": "Feature", "id": 282, "properties": { "store_name": "Narvesen", "address": "Prāgas iela 1 (NB 1151)", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1146827, 56.9450497] } },
        { "type": "Feature", "id": 283, "properties": { "store_name": "Narvesen", "address": "Sarkandaugavas 26", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.125272, 57.0012086] } },
        { "type": "Feature", "id": 284, "properties": { "store_name": "Narvesen", "address": "Dzirciema 51", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.051606, 56.9596836] } },
        { "type": "Feature", "id": 285, "properties": { "store_name": "Narvesen", "address": "Melnsila 21", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0645596, 56.9447208] } },
        { "type": "Feature", "id": 286, "properties": { "store_name": "Narvesen", "address": "Maskavas-Kaņieru", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1755242, 56.9149468] } },
        { "type": "Feature", "id": 287, "properties": { "store_name": "Narvesen", "address": "Vaidavas 15", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1762584, 56.9612352] } },
        { "type": "Feature", "id": 288, "properties": { "store_name": "Narvesen", "address": "Brīvības 432", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2418491, 56.9891357] } },
        { "type": "Feature", "id": 289, "properties": { "store_name": "Narvesen", "address": "Valdeķu 10", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0975403, 56.9158925] } },
        { "type": "Feature", "id": 290, "properties": { "store_name": "Narvesen", "address": "A.Dombrovska 23", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0192721, 56.9512281] } },
        { "type": "Feature", "id": 291, "properties": { "store_name": "Narvesen", "address": "Plēksnes ielā 9/11", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0286582, 57.0412494] } },
        { "type": "Feature", "id": 292, "properties": { "store_name": "Narvesen", "address": "Ulbrokas 11", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2162774, 56.9443135] } },
        { "type": "Feature", "id": 293, "properties": { "store_name": "Narvesen", "address": "Zentenes 11", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0063145, 56.9587615] } },
        { "type": "Feature", "id": 294, "properties": { "store_name": "Narvesen", "address": "Dzelzavas - 17. trol. galap.", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1720748, 56.9622841] } },
        { "type": "Feature", "id": 295, "properties": { "store_name": "Narvesen", "address": "Jūrmalas gatve 85 (Jūra)", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0063956, 56.9541756] } },
        { "type": "Feature", "id": 296, "properties": { "store_name": "Narvesen", "address": "Saharova iela 21", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.202475, 56.938316] } },
        { "type": "Feature", "id": 297, "properties": { "store_name": "Narvesen", "address": "13 janvāra-Ridzenes krust.", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.11311, 56.94543] } },
        { "type": "Feature", "id": 298, "properties": { "store_name": "Narvesen", "address": "Limbaži stacija lauk. 7", "postcode": "", "city": "Limbaži" }, "geometry": { "type": "Point", "coordinates": [24.7219133, 57.5090365] } },
        { "type": "Feature", "id": 299, "properties": { "store_name": "Narvesen", "address": "Uzvaras bulv. 3a", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0948453, 56.9419929] } },
        { "type": "Feature", "id": 300, "properties": { "store_name": "Narvesen", "address": "Dzelzavas 73/75", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1987551, 56.9562482] } },
        { "type": "Feature", "id": 301, "properties": { "store_name": "Narvesen", "address": "Dombrovska 74", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0192721, 56.9512281] } },
        { "type": "Feature", "id": 302, "properties": { "store_name": "Narvesen", "address": "Skolas 2", "postcode": "", "city": "Salaspils" }, "geometry": { "type": "Point", "coordinates": [24.3369771, 56.8539028] } },
        { "type": "Feature", "id": 303, "properties": { "store_name": "Narvesen", "address": "Hipokrāta iela 7", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2396082, 56.9671509] } },
        { "type": "Feature", "id": 304, "properties": { "store_name": "Narvesen", "address": "A.Deglava ielā 160", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2221025, 56.9436032] } },
        { "type": "Feature", "id": 305, "properties": { "store_name": "Narvesen", "address": "Brīvības iela 9", "postcode": "", "city": "Ogre" }, "geometry": { "type": "Point", "coordinates": [24.6016174, 56.8156593] } },
        { "type": "Feature", "id": 306, "properties": { "store_name": "Narvesen", "address": "Mālkalnes pr. 14", "postcode": "", "city": "Ogre" }, "geometry": { "type": "Point", "coordinates": [24.597861, 56.8228405] } },
        { "type": "Feature", "id": 307, "properties": { "store_name": "Narvesen", "address": "Zemgales pros. 17", "postcode": "", "city": "Jelgava" }, "geometry": { "type": "Point", "coordinates": [23.7196411, 56.6511478] } },
        { "type": "Feature", "id": 308, "properties": { "store_name": "Narvesen", "address": "Pasta 26, Autostacija", "postcode": "", "city": "Jelgava" }, "geometry": { "type": "Point", "coordinates": [23.7196411, 56.6511478] } },
        { "type": "Feature", "id": 309, "properties": { "store_name": "Narvesen", "address": "Dzelzceļa stacijas teritorijā", "postcode": "", "city": "Carnikava" }, "geometry": { "type": "Point", "coordinates": [24.2856604, 57.1281138] } },
        { "type": "Feature", "id": 310, "properties": { "store_name": "Narvesen", "address": "Gaismas iela 17", "postcode": "", "city": "Ķekava" }, "geometry": { "type": "Point", "coordinates": [24.2298447, 56.8241551] } },
        { "type": "Feature", "id": 311, "properties": { "store_name": "Narvesen", "address": "Slokas stacija", "postcode": "", "city": "Jūrmala" }, "geometry": { "type": "Point", "coordinates": [23.6166465, 56.9470457] } },
        { "type": "Feature", "id": 312, "properties": { "store_name": "Narvesen", "address": "Elizabetes - Tērbatas", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1195164, 56.9529348] } },
        { "type": "Feature", "id": 313, "properties": { "store_name": "Narvesen", "address": "Gaismas 12", "postcode": "", "city": "Aizkraukle" }, "geometry": { "type": "Point", "coordinates": [25.2495222, 56.6017285] } },
        { "type": "Feature", "id": 314, "properties": { "store_name": "Narvesen", "address": "Vecpilsētas laukums", "postcode": "", "city": "Jēkabpils" }, "geometry": { "type": "Point", "coordinates": [25.8664019, 56.4976076] } },
        { "type": "Feature", "id": 315, "properties": { "store_name": "Narvesen", "address": "Pasta 18", "postcode": "", "city": "Jēkabpils" }, "geometry": { "type": "Point", "coordinates": [25.8675147, 56.5027578] } },
        { "type": "Feature", "id": 316, "properties": { "store_name": "Narvesen", "address": "Vienības 1 (Autoosta)", "postcode": "", "city": "Jēkabpils" }, "geometry": { "type": "Point", "coordinates": [25.8768613, 56.4938105] } },
        { "type": "Feature", "id": 317, "properties": { "store_name": "Narvesen", "address": "Poruka 2a", "postcode": "", "city": "Madona" }, "geometry": { "type": "Point", "coordinates": [26.2179178, 56.8508647] } },
        { "type": "Feature", "id": 318, "properties": { "store_name": "Narvesen", "address": "Rīgas 10", "postcode": "", "city": "Valmiera" }, "geometry": { "type": "Point", "coordinates": [25.4209012, 57.5386991] } },
        { "type": "Feature", "id": 319, "properties": { "store_name": "Narvesen", "address": "Pils 1", "postcode": "", "city": "Smiltene" }, "geometry": { "type": "Point", "coordinates": [25.9057707, 57.4249027] } },
        { "type": "Feature", "id": 320, "properties": { "store_name": "Narvesen", "address": "Rīgas ielā 37a", "postcode": "", "city": "Gulbene" }, "geometry": { "type": "Point", "coordinates": [26.7574427, 57.1714112] } },
        { "type": "Feature", "id": 321, "properties": { "store_name": "Narvesen", "address": "Pils 68", "postcode": "", "city": "Alūksne" }, "geometry": { "type": "Point", "coordinates": [27.0513772, 57.4208278] } },
        { "type": "Feature", "id": 322, "properties": { "store_name": "Narvesen", "address": "Tirgotāju 13", "postcode": "", "city": "Alūksne" }, "geometry": { "type": "Point", "coordinates": [27.0513437, 57.4211174] } },
        { "type": "Feature", "id": 323, "properties": { "store_name": "Narvesen", "address": "Bērzpils 1a", "postcode": "", "city": "Balvi" }, "geometry": { "type": "Point", "coordinates": [27.2683496, 57.1315756] } },
        { "type": "Feature", "id": 324, "properties": { "store_name": "Narvesen", "address": "Rēzekne, Atbrīvošanas al.un Rancānu iela", "postcode": "", "city": "Rēzekne" }, "geometry": { "type": "Point", "coordinates": [27.334963, 56.5133088] } },
        { "type": "Feature", "id": 325, "properties": { "store_name": "Narvesen", "address": "Latgales 20a", "postcode": "", "city": "Rēzekne" }, "geometry": { "type": "Point", "coordinates": [27.3302549, 56.4995193] } },
        { "type": "Feature", "id": 326, "properties": { "store_name": "Narvesen", "address": "Atbrīvošanas 141a, Maxima", "postcode": "", "city": "Rēzekne" }, "geometry": { "type": "Point", "coordinates": [27.3403943, 56.5233038] } },
        { "type": "Feature", "id": 327, "properties": { "store_name": "Narvesen", "address": "Rīgas 110", "postcode": "", "city": "Līvāni" }, "geometry": { "type": "Point", "coordinates": [26.1760979, 56.3545634] } },
        { "type": "Feature", "id": 328, "properties": { "store_name": "Narvesen", "address": "Brīvības iela 2", "postcode": "", "city": "Preiļi" }, "geometry": { "type": "Point", "coordinates": [26.723469, 56.2946193] } },
        { "type": "Feature", "id": 329, "properties": { "store_name": "Narvesen", "address": "Cialkovska 3", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.5607222, 55.8942623] } },
        { "type": "Feature", "id": 330, "properties": { "store_name": "Narvesen", "address": "18. Novembra iela 186", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.5571027, 55.8804654] } },
        { "type": "Feature", "id": 331, "properties": { "store_name": "Narvesen", "address": "Vasarnīcu 20", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.591874, 55.898382] } },
        { "type": "Feature", "id": 332, "properties": { "store_name": "Narvesen", "address": "Ģimnāzijas 31", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.5179863, 55.8703687] } },
        { "type": "Feature", "id": 333, "properties": { "store_name": "Narvesen", "address": "Viestura 10", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.5249815, 55.8714184] } },
        { "type": "Feature", "id": 334, "properties": { "store_name": "Narvesen", "address": "Cietokšņa 60 (Diitton nams)", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.5182138, 55.8733203] } },
        { "type": "Feature", "id": 335, "properties": { "store_name": "Narvesen", "address": "Iecavas Tirgus 7d", "postcode": "", "city": "Iecava" }, "geometry": { "type": "Point", "coordinates": [24.1970323, 56.5987084] } },
        { "type": "Feature", "id": 336, "properties": { "store_name": "Narvesen", "address": "Slimnīcas 11, autoosta", "postcode": "", "city": "Bauska" }, "geometry": { "type": "Point", "coordinates": [24.1959208, 56.4068964] } },
        { "type": "Feature", "id": 337, "properties": { "store_name": "Narvesen", "address": "Pionieru 2 (Rimi)", "postcode": "", "city": "Bauska" }, "geometry": { "type": "Point", "coordinates": [24.1886274, 56.4057598] } },
        { "type": "Feature", "id": 338, "properties": { "store_name": "Narvesen", "address": "Raiņa 7a", "postcode": "", "city": "Tukums" }, "geometry": { "type": "Point", "coordinates": [23.1545143, 56.9670823] } },
        { "type": "Feature", "id": 339, "properties": { "store_name": "Narvesen", "address": "Kurzemes 3a", "postcode": "", "city": "Tukums" }, "geometry": { "type": "Point", "coordinates": [23.1621581, 56.9703341] } },
        { "type": "Feature", "id": 340, "properties": { "store_name": "Narvesen", "address": "Uzvaras iela 7b", "postcode": "", "city": "Dobele" }, "geometry": { "type": "Point", "coordinates": [23.2791926, 56.6246818] } },
        { "type": "Feature", "id": 341, "properties": { "store_name": "Narvesen", "address": "Stacijas laukums", "postcode": "", "city": "Dobele" }, "geometry": { "type": "Point", "coordinates": [23.2844905, 56.628479] } },
        { "type": "Feature", "id": 342, "properties": { "store_name": "Narvesen", "address": "Dundagas 15", "postcode": "", "city": "Talsi" }, "geometry": { "type": "Point", "coordinates": [22.5795696, 57.248303] } },
        { "type": "Feature", "id": 343, "properties": { "store_name": "Narvesen", "address": "Liepājas 38", "postcode": "", "city": "Kuldīga" }, "geometry": { "type": "Point", "coordinates": [21.9619444, 56.9683528] } },
        { "type": "Feature", "id": 344, "properties": { "store_name": "Narvesen", "address": "Siena 2/4", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0100507, 56.504135] } },
        { "type": "Feature", "id": 345, "properties": { "store_name": "Narvesen", "address": "Autoru 30", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0140912, 56.5205751] } },
        { "type": "Feature", "id": 346, "properties": { "store_name": "Narvesen", "address": "Lielā 16", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0107626, 56.50771] } },
        { "type": "Feature", "id": 347, "properties": { "store_name": "Narvesen", "address": "M.Ķempes iela 5", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0182807, 56.4835435] } },
        { "type": "Feature", "id": 348, "properties": { "store_name": "Narvesen", "address": "Klaipēdas 62", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0042994, 56.4917716] } },
        { "type": "Feature", "id": 349, "properties": { "store_name": "Narvesen", "address": "Lielā 11", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0124712, 56.5095924] } },
        { "type": "Feature", "id": 350, "properties": { "store_name": "Narvesen", "address": "Kuldīgas 23", "postcode": "", "city": "Ventspils" }, "geometry": { "type": "Point", "coordinates": [21.5643944, 57.3924412] } },
        { "type": "Feature", "id": 351, "properties": { "store_name": "Narvesen", "address": "Tirgus lauk.", "postcode": "", "city": "Ventspils" }, "geometry": { "type": "Point", "coordinates": [21.5673157, 57.3972825] } },
        { "type": "Feature", "id": 352, "properties": { "store_name": "Narvesen", "address": "Aleksandra 16, Maxima", "postcode": "", "city": "Ventspils" }, "geometry": { "type": "Point", "coordinates": [21.620621, 57.4088355] } },
        { "type": "Feature", "id": 353, "properties": { "store_name": "Lats", "address": "Pļavas', Turlavas pag., Kuldīgas nov.", "postcode": "", "city": "Turlavas pagasts" }, "geometry": { "type": "Point", "coordinates": [21.754693, 56.8166745] } },
        { "type": "Feature", "id": 354, "properties": { "store_name": "Lats", "address": "Lielā iela 29", "postcode": "", "city": "Grobiņa" }, "geometry": { "type": "Point", "coordinates": [21.1654373, 56.5354794] } },
        { "type": "Feature", "id": 355, "properties": { "store_name": "Lats", "address": "Mazieviņas', Kalētu pag., Priekules nov.", "postcode": "", "city": "Kalētu pagasts" }, "geometry": { "type": "Point", "coordinates": [21.4788543, 56.3619619] } },
        { "type": "Feature", "id": 356, "properties": { "store_name": "Lats", "address": "Skolas iela 3", "postcode": "", "city": "Alsunga" }, "geometry": { "type": "Point", "coordinates": [21.5699819, 56.9794059] } },
        { "type": "Feature", "id": 357, "properties": { "store_name": "Lats", "address": "Kurzemnieki', Krote, Bunkas pag., Priekules nov.", "postcode": "", "city": "Bunkas pagasts" }, "geometry": { "type": "Point", "coordinates": [21.5479454, 56.5423206] } },
        { "type": "Feature", "id": 358, "properties": { "store_name": "Lats", "address": "Lielā iela 4", "postcode": "", "city": "Piltene" }, "geometry": { "type": "Point", "coordinates": [21.6733241, 57.2234468] } },
        { "type": "Feature", "id": 359, "properties": { "store_name": "Lats", "address": "Selgas iela 14d", "postcode": "", "city": "Roja" }, "geometry": { "type": "Point", "coordinates": [22.807567, 57.5038529] } },
        { "type": "Feature", "id": 360, "properties": { "store_name": "Lats", "address": "Laidzes iela 24-5", "postcode": "", "city": "Talsi" }, "geometry": { "type": "Point", "coordinates": [22.5999469, 57.2484915] } },
        { "type": "Feature", "id": 361, "properties": { "store_name": "Lats", "address": "Siļķu iela 20-6", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0081526, 56.5318896] } },
        { "type": "Feature", "id": 362, "properties": { "store_name": "Lats", "address": "Mazcenu aleja 6-12", "postcode": "", "city": "Jaunmārupe" }, "geometry": { "type": "Point", "coordinates": [23.9463108, 56.88025] } },
        { "type": "Feature", "id": 363, "properties": { "store_name": "Lats", "address": "Mazā Nometņu iela 18", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0763263, 56.9338092] } },
        { "type": "Feature", "id": 364, "properties": { "store_name": "Lats", "address": "Spriņģu iela 1-604", "postcode": "", "city": "Līgatne" }, "geometry": { "type": "Point", "coordinates": [25.0401709, 57.233543] } },
        { "type": "Feature", "id": 365, "properties": { "store_name": "Lats", "address": "Struktoru iela 4B", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1727316, 56.973519] } },
        { "type": "Feature", "id": 366, "properties": { "store_name": "Lats", "address": "Ozolu iela 5", "postcode": "", "city": "Kandava" }, "geometry": { "type": "Point", "coordinates": [22.7733518, 57.0330174] } },
        { "type": "Feature", "id": 367, "properties": { "store_name": "Lats", "address": "Liedaga iela 11", "postcode": "", "city": "Liepupes pagasts" }, "geometry": { "type": "Point", "coordinates": [24.3883163, 57.4906104] } },
        { "type": "Feature", "id": 368, "properties": { "store_name": "Lats", "address": "Slimnīcas iela 11", "postcode": "", "city": "Bauska" }, "geometry": { "type": "Point", "coordinates": [24.195913, 56.40688] } },
        { "type": "Feature", "id": 369, "properties": { "store_name": "Lats", "address": "Raiņa iela 28", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0107576, 56.518087] } },
        { "type": "Feature", "id": 370, "properties": { "store_name": "Lats", "address": "Alejas, Jaunpils nov., Jaunpils pag., LV-3145", "postcode": "", "city": "Jaunpils pagasts" }, "geometry": { "type": "Point", "coordinates": [23.0125321, 56.7314559] } },
        { "type": "Feature", "id": 371, "properties": { "store_name": "Lats", "address": "Maiņkalni, Lēdurgas pag.", "postcode": "", "city": "Lēdurgas pagasts" }, "geometry": { "type": "Point", "coordinates": [24.742397, 57.3217906] } },
        { "type": "Feature", "id": 372, "properties": { "store_name": "Lats", "address": "Grīzupes iela 91NT", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0914071, 56.5535518] } },
        { "type": "Feature", "id": 373, "properties": { "store_name": "Lats", "address": "Spīdolas iela 1", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0366576, 56.5529228] } },
        { "type": "Feature", "id": 374, "properties": { "store_name": "Lats", "address": "Siļķu iela 22–2N", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0088582, 56.5319025] } },
        { "type": "Feature", "id": 375, "properties": { "store_name": "Lats", "address": "Daugavas prospekts 38, Ikšķile, Ikšķiles nov., veikals", "postcode": "", "city": "Ikšķile" }, "geometry": { "type": "Point", "coordinates": [24.5049378, 56.8391202] } },
        { "type": "Feature", "id": 376, "properties": { "store_name": "Lats", "address": "Stendes iela 8, Talsi, Talsu nov., DUS veikals", "postcode": "", "city": "Talsi" }, "geometry": { "type": "Point", "coordinates": [22.5887774, 57.2337117] } },
        { "type": "Feature", "id": 377, "properties": { "store_name": "Lats", "address": "Hospitāļu iela 1–103, Rīga, veikals", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1354237, 56.9690237] } },
        { "type": "Feature", "id": 378, "properties": { "store_name": "Lats", "address": "Bauskas iela 2, Rīga, veikals 'LATS Bauskas'", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.093051, 56.9261483] } },
        { "type": "Feature", "id": 379, "properties": { "store_name": "Lats", "address": "Skolas iela 6-14, Augšlīgatne, Līgatnes pag., Līgatnes nov., veikals-kafejnīca", "postcode": "", "city": "Augšlīgatne" }, "geometry": { "type": "Point", "coordinates": [25.0386669, 57.1880773] } },
        { "type": "Feature", "id": 380, "properties": { "store_name": "Lats", "address": "'Sadzīves pakalpojumu paviljons', Madlienas pag., Ogres nov. veikals", "postcode": "", "city": "Madlienas pagasts" }, "geometry": { "type": "Point", "coordinates": [25.2317181, 56.8275396] } },
        { "type": "Feature", "id": 381, "properties": { "store_name": "Lats", "address": "'Līdumnieki', Raiskuma pag., Pārgaujas nov., veikals", "postcode": "", "city": "Raiskuma pagasts" }, "geometry": { "type": "Point", "coordinates": [25.143432, 57.3388855] } },
        { "type": "Feature", "id": 382, "properties": { "store_name": "Lats", "address": "Taisnā iela 26", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2704793, 56.8677113] } },
        { "type": "Feature", "id": 383, "properties": { "store_name": "Lats", "address": "Tirgotava, Pilsrundāle, Rundāles pag., Bauskas nov.", "postcode": "", "city": "Pilsrundāle" }, "geometry": { "type": "Point", "coordinates": [24.0113847, 56.4195639] } },
        { "type": "Feature", "id": 384, "properties": { "store_name": "Lats", "address": "Vecozoli', Lizums, Lizuma pag., Gulbenes nov.", "postcode": "", "city": "Lizums" }, "geometry": { "type": "Point", "coordinates": [26.3699366, 57.1922558] } },
        { "type": "Feature", "id": 385, "properties": { "store_name": "Lats", "address": "Akmeņu iela 50", "postcode": "", "city": "Ogre" }, "geometry": { "type": "Point", "coordinates": [24.6137286, 56.7976232] } },
        { "type": "Feature", "id": 386, "properties": { "store_name": "Lats", "address": "Kadaga 15', Kadaga, Ādažu nov.", "postcode": "", "city": "Kadaga" }, "geometry": { "type": "Point", "coordinates": [24.3648757, 57.0944292] } },
        { "type": "Feature", "id": 387, "properties": { "store_name": "Lats", "address": "Zahārija Stopija iela 10, Upeslejas.", "postcode": "", "city": "Stopiņu pagasts" }, "geometry": { "type": "Point", "coordinates": [24.3788127, 56.9216897] } },
        { "type": "Feature", "id": 388, "properties": { "store_name": "Lats", "address": "Raiņa iela 29", "postcode": "", "city": "Durbe" }, "geometry": { "type": "Point", "coordinates": [21.3685632, 56.5892934] } },
        { "type": "Feature", "id": 389, "properties": { "store_name": "Lats", "address": "Ģenerāļa Baloža iela 33", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0289368, 56.5532561] } },
        { "type": "Feature", "id": 390, "properties": { "store_name": "Lats", "address": "Jelgavas iela 3A", "postcode": "", "city": "Jaunjelgava" }, "geometry": { "type": "Point", "coordinates": [25.0758469, 56.6163724] } },
        { "type": "Feature", "id": 391, "properties": { "store_name": "Lats", "address": "Lilijas  iela 9", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0681471, 56.9641636] } },
        { "type": "Feature", "id": 392, "properties": { "store_name": "Lats", "address": "Lielcieceres iela 15", "postcode": "", "city": "Brocēni" }, "geometry": { "type": "Point", "coordinates": [22.5693955, 56.6795012] } },
        { "type": "Feature", "id": 393, "properties": { "store_name": "Lats", "address": "Slimnīcas iela 15", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.09884, 56.5551531] } },
        { "type": "Feature", "id": 394, "properties": { "store_name": "Lats", "address": "Saules iela 3A", "postcode": "", "city": "Aizpute" }, "geometry": { "type": "Point", "coordinates": [21.597648, 56.7242672] } },
        { "type": "Feature", "id": 395, "properties": { "store_name": "Lats", "address": "Biķernieku iela 1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.16631, 56.9726836] } },
        { "type": "Feature", "id": 396, "properties": { "store_name": "Lats", "address": "Gaujas iela 21a", "postcode": "", "city": "Jaunpiebalga" }, "geometry": { "type": "Point", "coordinates": [26.0384683, 57.1766124] } },
        { "type": "Feature", "id": 397, "properties": { "store_name": "Lats", "address": "Skolas iela 6-11", "postcode": "", "city": "Lutriņi" }, "geometry": { "type": "Point", "coordinates": [22.3986338, 56.7329365] } },
        { "type": "Feature", "id": 398, "properties": { "store_name": "Lats", "address": "Raiņa iela 23", "postcode": "", "city": "Auce" }, "geometry": { "type": "Point", "coordinates": [22.894379, 56.4625165] } },
        { "type": "Feature", "id": 399, "properties": { "store_name": "Lats", "address": "Vesetas iela 5", "postcode": "", "city": "Jaunkalsnava" }, "geometry": { "type": "Point", "coordinates": [25.9698682, 56.6886572] } },
        { "type": "Feature", "id": 400, "properties": { "store_name": "Lats", "address": "Gaujas iela 27b", "postcode": "", "city": "Ādaži" }, "geometry": { "type": "Point", "coordinates": [24.3274079, 57.0808739] } },
        { "type": "Feature", "id": 401, "properties": { "store_name": "Lats", "address": "Alejas iela 7", "postcode": "", "city": "Kocēni" }, "geometry": { "type": "Point", "coordinates": [25.340152, 57.521125] } },
        { "type": "Feature", "id": 402, "properties": { "store_name": "Lats", "address": "Zaļā iela 87", "postcode": "", "city": "Dobele" }, "geometry": { "type": "Point", "coordinates": [23.2917072, 56.6130431] } },
        { "type": "Feature", "id": 403, "properties": { "store_name": "Lats", "address": "Brīvības iela 94", "postcode": "", "city": "Ogre" }, "geometry": { "type": "Point", "coordinates": [24.6356385, 56.8123832] } },
        { "type": "Feature", "id": 404, "properties": { "store_name": "Lats", "address": "'Bārta', Bārtas pag., Grobiņas nov, LV-3430", "postcode": "", "city": "Bārtas pagasts" }, "geometry": { "type": "Point", "coordinates": [21.3203052, 56.3521978] } },
        { "type": "Feature", "id": 405, "properties": { "store_name": "Lats", "address": "Rīgas iela 6", "postcode": "", "city": "Strenči" }, "geometry": { "type": "Point", "coordinates": [25.6839754, 57.6276581] } },
        { "type": "Feature", "id": 406, "properties": { "store_name": "Lats", "address": "Grīzupes iela 24A", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0676922, 56.5435724] } },
        { "type": "Feature", "id": 407, "properties": { "store_name": "Lats", "address": "Brīvības iela 45a", "postcode": "", "city": "Vaiņode" }, "geometry": { "type": "Point", "coordinates": [21.872097, 56.4219775] } },
        { "type": "Feature", "id": 408, "properties": { "store_name": "Lats", "address": "Skolas iela 8", "postcode": "", "city": "Kalvene" }, "geometry": { "type": "Point", "coordinates": [21.7376961, 56.6045906] } },
        { "type": "Feature", "id": 409, "properties": { "store_name": "Lats", "address": "Lindes iela 4", "postcode": "", "city": "Birzgale" }, "geometry": { "type": "Point", "coordinates": [24.7568342, 56.6320054] } },
        { "type": "Feature", "id": 410, "properties": { "store_name": "Lats", "address": "Rīgas-Liepājas šosejas 169 km", "postcode": "", "city": "Durbe" }, "geometry": { "type": "Point", "coordinates": [21.1282473, 56.5342262] } },
        { "type": "Feature", "id": 411, "properties": { "store_name": "Lats", "address": "Liepu iela 5–1", "postcode": "", "city": "Īslīces pagasts" }, "geometry": { "type": "Point", "coordinates": [24.1732675, 56.3817463] } },
        { "type": "Feature", "id": 412, "properties": { "store_name": "Lats", "address": "Nītaures iela 10", "postcode": "", "city": "Augšlīgatne" }, "geometry": { "type": "Point", "coordinates": [25.0338203, 57.1828184] } },
        { "type": "Feature", "id": 413, "properties": { "store_name": "Lats", "address": "1.maija laukums 3", "postcode": "", "city": "Varakļāni" }, "geometry": { "type": "Point", "coordinates": [26.7515902, 56.6104566] } },
        { "type": "Feature", "id": 414, "properties": { "store_name": "Lats", "address": "Vidzemes iela 7g", "postcode": "", "city": "Balvi" }, "geometry": { "type": "Point", "coordinates": [27.2482068, 57.1383345] } },
        { "type": "Feature", "id": 415, "properties": { "store_name": "Lats", "address": "Jāņa Poruka iela 19", "postcode": "", "city": "Cēsis" }, "geometry": { "type": "Point", "coordinates": [25.2922904, 57.3144738] } },
        { "type": "Feature", "id": 416, "properties": { "store_name": "Lats", "address": "Kungu iela 52-1", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0222673, 56.5057599] } },
        { "type": "Feature", "id": 417, "properties": { "store_name": "Lats", "address": "Dzērves iela 15", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0211471, 56.492839] } },
        { "type": "Feature", "id": 418, "properties": { "store_name": "Lats", "address": "Krūmu iela 52a", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0121774, 56.533196] } },
        { "type": "Feature", "id": 419, "properties": { "store_name": "Lats", "address": "Georga Apiņa iela 33", "postcode": "", "city": "Valmiera" }, "geometry": { "type": "Point", "coordinates": [25.4138227, 57.5449252] } },
        { "type": "Feature", "id": 420, "properties": { "store_name": "Lats", "address": "Leona Paegles iela 4", "postcode": "", "city": "Cēsis" }, "geometry": { "type": "Point", "coordinates": [25.278904, 57.3045694] } },
        { "type": "Feature", "id": 421, "properties": { "store_name": "Lats", "address": "'Būvmateriālu veikals Piebalga' , veikals, Vecpiebalga, Vecpiebalgas pag.", "postcode": "", "city": "Vecpiebalga" }, "geometry": { "type": "Point", "coordinates": [25.8175959, 57.061272] } },
        { "type": "Feature", "id": 422, "properties": { "store_name": "Lats", "address": "Stacijas iela 2, veikals", "postcode": "", "city": "Valmiera" }, "geometry": { "type": "Point", "coordinates": [25.4261536, 57.5332951] } },
        { "type": "Feature", "id": 423, "properties": { "store_name": "Lats", "address": "Ausekļa iela 32", "postcode": "", "city": "Valmiera" }, "geometry": { "type": "Point", "coordinates": [25.3968203, 57.5277344] } },
        { "type": "Feature", "id": 424, "properties": { "store_name": "Lats", "address": "Rīgas iela 24", "postcode": "", "city": "Valmiera" }, "geometry": { "type": "Point", "coordinates": [25.41569, 57.537984] } },
        { "type": "Feature", "id": 425, "properties": { "store_name": "Lats", "address": "'Līči', Stalbe, Stalbes pag., Pārgaujas nov., veikals", "postcode": "", "city": "Stalbe" }, "geometry": { "type": "Point", "coordinates": [25.0328153, 57.371049] } },
        { "type": "Feature", "id": 426, "properties": { "store_name": "Lats", "address": "Rīgas iela 19", "postcode": "", "city": "Stopiņu pagasts" }, "geometry": { "type": "Point", "coordinates": [24.3037773, 56.9394145] } },
        { "type": "Feature", "id": 427, "properties": { "store_name": "Lats", "address": "Rīgas iela 24b", "postcode": "", "city": "Vecumnieki" }, "geometry": { "type": "Point", "coordinates": [24.5210652, 56.6062843] } },
        { "type": "Feature", "id": 428, "properties": { "store_name": "Lats", "address": "Rīgas iela 10", "postcode": "", "city": "Ropaži" }, "geometry": { "type": "Point", "coordinates": [24.6341274, 56.9719565] } },
        { "type": "Feature", "id": 429, "properties": { "store_name": "Lats", "address": "Tirgus iela 6", "postcode": "", "city": "Iecava" }, "geometry": { "type": "Point", "coordinates": [24.19723, 56.5985748] } },
        { "type": "Feature", "id": 430, "properties": { "store_name": "Lats", "address": "Lielā iela 73", "postcode": "", "city": "Saldus" }, "geometry": { "type": "Point", "coordinates": [22.4810597, 56.6560374] } },
        { "type": "Feature", "id": 431, "properties": { "store_name": "Lats", "address": "Vītolu iela 12", "postcode": "", "city": "Grobiņa" }, "geometry": { "type": "Point", "coordinates": [21.1876994, 56.5357569] } },
        { "type": "Feature", "id": 432, "properties": { "store_name": "Lats", "address": "Daugavas iela 4", "postcode": "", "city": "Baldones pagasts" }, "geometry": { "type": "Point", "coordinates": [24.3955578, 56.7432748] } },
        { "type": "Feature", "id": 433, "properties": { "store_name": "Lats", "address": "Gaujas iela 56a", "postcode": "", "city": "Cēsis" }, "geometry": { "type": "Point", "coordinates": [25.2487786, 57.3093315] } },
        { "type": "Feature", "id": 434, "properties": { "store_name": "Lats", "address": "Tērbatas iela 43", "postcode": "", "city": "Valmiera" }, "geometry": { "type": "Point", "coordinates": [25.433299, 57.5481178] } },
        { "type": "Feature", "id": 435, "properties": { "store_name": "Lats", "address": "Dzintaru iela 88a", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0025636, 56.4935191] } },
        { "type": "Feature", "id": 436, "properties": { "store_name": "Lats", "address": "Skolas iela 2", "postcode": "", "city": "Vaidava" }, "geometry": { "type": "Point", "coordinates": [25.2862439, 57.429603] } },
        { "type": "Feature", "id": 437, "properties": { "store_name": "Lats", "address": "'Veikals Riesti', Rude,  Rojas pag., Rojas nov., veikals", "postcode": "", "city": "Rude" }, "geometry": { "type": "Point", "coordinates": [22.7277015, 57.4908722] } },
        { "type": "Feature", "id": 438, "properties": { "store_name": "Lats", "address": "Beātes iela 21", "postcode": "", "city": "Valmiera" }, "geometry": { "type": "Point", "coordinates": [25.4114878, 57.5400878] } },
        { "type": "Feature", "id": 439, "properties": { "store_name": "Lats", "address": "Raiņa iela 2", "postcode": "", "city": "Vaiņode" }, "geometry": { "type": "Point", "coordinates": [21.8557984, 56.4174851] } },
        { "type": "Feature", "id": 440, "properties": { "store_name": "Lats", "address": "Daugavas iela 105 a", "postcode": "", "city": "Skrīvēri" }, "geometry": { "type": "Point", "coordinates": [25.1232729, 56.6525413] } },
        { "type": "Feature", "id": 441, "properties": { "store_name": "Lats", "address": "Kr. Valdemāra iela 80-28N", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0064159, 56.4977215] } },
        { "type": "Feature", "id": 442, "properties": { "store_name": "Lats", "address": "Roņu iela 5NT", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [20.9929197, 56.5147389] } },
        { "type": "Feature", "id": 443, "properties": { "store_name": "Lats", "address": "Braslas iela 1", "postcode": "", "city": "Straupe" }, "geometry": { "type": "Point", "coordinates": [24.9510791, 57.3554409] } },
        { "type": "Feature", "id": 444, "properties": { "store_name": "Lats", "address": "Rūpniecības iela 8A", "postcode": "", "city": "Cēsis" }, "geometry": { "type": "Point", "coordinates": [25.2992135, 57.3196688] } },
        { "type": "Feature", "id": 445, "properties": { "store_name": "Lats", "address": "Uliha iela 15/17", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [20.9986018, 56.512421] } },
        { "type": "Feature", "id": 446, "properties": { "store_name": "Lats", "address": "'Vīganti', Sējas nov., veikals", "postcode": "", "city": "Sējas nov." }, "geometry": { "type": "Point", "coordinates": [24.6643167, 57.1326615] } },
        { "type": "Feature", "id": 447, "properties": { "store_name": "Lats", "address": "Torņa iela 2a", "postcode": "", "city": "Alūksne" }, "geometry": { "type": "Point", "coordinates": [27.0668, 57.4199483] } },
        { "type": "Feature", "id": 448, "properties": { "store_name": "Lats", "address": "Lielā iela 29", "postcode": "", "city": "Grobiņa" }, "geometry": { "type": "Point", "coordinates": [21.1654373, 56.5354794] } },
        { "type": "Feature", "id": 449, "properties": { "store_name": "Lats", "address": "Klāva Ukstiņa iela 41/43", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0171864, 56.5007013] } },
        { "type": "Feature", "id": 450, "properties": { "store_name": "Lats", "address": "Kārļa Baumaņa iela 3", "postcode": "", "city": "Valmiera" }, "geometry": { "type": "Point", "coordinates": [25.4199586, 57.5369436] } },
        { "type": "Feature", "id": 451, "properties": { "store_name": "Lats", "address": "Meldri', Virgas pag., Priekules nov.", "postcode": "", "city": "Virgas pagasts" }, "geometry": { "type": "Point", "coordinates": [21.4137339, 56.4745256] } },
        { "type": "Feature", "id": 452, "properties": { "store_name": "Lats", "address": "Valdeķu iela 65–66", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0912158, 56.8991848] } },
        { "type": "Feature", "id": 453, "properties": { "store_name": "Lats", "address": "Ropažu iela 50-1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1770592, 56.9795694] } },
        { "type": "Feature", "id": 454, "properties": { "store_name": "Lats", "address": "Stacijas iela 44", "postcode": "", "city": "Valmiera" }, "geometry": { "type": "Point", "coordinates": [25.4386059, 57.5233517] } },
        { "type": "Feature", "id": 455, "properties": { "store_name": "Lats", "address": "Mazā iela 4", "postcode": "", "city": "Ragana" }, "geometry": { "type": "Point", "coordinates": [24.7134533, 57.1811569] } },
        { "type": "Feature", "id": 456, "properties": { "store_name": "Lats", "address": "Eksporta iela 14", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0982461, 56.9697959] } },
        { "type": "Feature", "id": 457, "properties": { "store_name": "Lats", "address": "Beātes iela 30a", "postcode": "", "city": "Valmiera" }, "geometry": { "type": "Point", "coordinates": [25.4076774, 57.5419197] } },
        { "type": "Feature", "id": 458, "properties": { "store_name": "Lats", "address": "Jūras iela 12", "postcode": "", "city": "Aloja" }, "geometry": { "type": "Point", "coordinates": [24.8841605, 57.7669123] } },
        { "type": "Feature", "id": 459, "properties": { "store_name": "Lats", "address": "Stacijas iela 8-8a", "postcode": "", "city": "Ape" }, "geometry": { "type": "Point", "coordinates": [26.693624, 57.5393473] } },
        { "type": "Feature", "id": 460, "properties": { "store_name": "Lats", "address": "Slokas iela 76", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0590301, 56.9599223] } },
        { "type": "Feature", "id": 461, "properties": { "store_name": "Lats", "address": "Partizānu iela 47", "postcode": "", "city": "Balvi" }, "geometry": { "type": "Point", "coordinates": [27.2607011, 57.1258359] } },
        { "type": "Feature", "id": 462, "properties": { "store_name": "CIRCLE K", "address": "Akadēmijas iela 20", "postcode": "", "city": "Jelgava" }, "geometry": { "type": "Point", "coordinates": [23.7302834, 56.6474969] } },
        { "type": "Feature", "id": 463, "properties": { "store_name": "CIRCLE K", "address": "Anniņmuižas bulvāris 25A", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0185035, 56.9596054] } },
        { "type": "Feature", "id": 464, "properties": { "store_name": "CIRCLE K", "address": "Aplis, Tīnūžu pag., Ogres nov.", "postcode": "", "city": "Tīnūžu pagasts" }, "geometry": { "type": "Point", "coordinates": [24.5661817, 56.8661589] } },
        { "type": "Feature", "id": 465, "properties": { "store_name": "CIRCLE K", "address": "Apvedceļš 11", "postcode": "", "city": "Saldus" }, "geometry": { "type": "Point", "coordinates": [22.4709665, 56.6823686] } },
        { "type": "Feature", "id": 466, "properties": { "store_name": "CIRCLE K", "address": "Atbrīvošanas aleja 143B", "postcode": "", "city": "Rēzekne" }, "geometry": { "type": "Point", "coordinates": [27.3411007, 56.5242485] } },
        { "type": "Feature", "id": 467, "properties": { "store_name": "CIRCLE K", "address": "Augusta Deglava iela 164C", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2297302, 56.9428251] } },
        { "type": "Feature", "id": 468, "properties": { "store_name": "CIRCLE K", "address": "Ausekļa iela 26", "postcode": "", "city": "Valmiera" }, "geometry": { "type": "Point", "coordinates": [25.3923917, 57.5304395] } },
        { "type": "Feature", "id": 469, "properties": { "store_name": "CIRCLE K", "address": "Biķernieku iela 121A", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2336982, 56.9583011] } },
        { "type": "Feature", "id": 470, "properties": { "store_name": "CIRCLE K", "address": "Brīvības bulvāris 1", "postcode": "", "city": "Jelgava" }, "geometry": { "type": "Point", "coordinates": [23.7438131, 56.6571396] } },
        { "type": "Feature", "id": 471, "properties": { "store_name": "CIRCLE K", "address": "Brīvības gatve 203", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1681189, 56.9739524] } },
        { "type": "Feature", "id": 472, "properties": { "store_name": "CIRCLE K", "address": "Brīvības gatve 265", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1844798, 56.9791942] } },
        { "type": "Feature", "id": 473, "properties": { "store_name": "CIRCLE K", "address": "Brīvības iela 176A", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1456346, 56.9662457] } },
        { "type": "Feature", "id": 474, "properties": { "store_name": "CIRCLE K", "address": "Brīvības iela 89A", "postcode": "", "city": "Gulbene" }, "geometry": { "type": "Point", "coordinates": [26.7425375, 57.1775541] } },
        { "type": "Feature", "id": 475, "properties": { "store_name": "CIRCLE K", "address": "Cēsu iela 30", "postcode": "", "city": "Limbaži" }, "geometry": { "type": "Point", "coordinates": [24.7279913, 57.5124402] } },
        { "type": "Feature", "id": 476, "properties": { "store_name": "CIRCLE K", "address": "Cesvaines iela 10", "postcode": "", "city": "Madona" }, "geometry": { "type": "Point", "coordinates": [26.2293336, 56.859227] } },
        { "type": "Feature", "id": 477, "properties": { "store_name": "CIRCLE K", "address": "Cielavas, Ragana, Krimuldas pag.", "postcode": "", "city": "Ragana" }, "geometry": { "type": "Point", "coordinates": [24.715941, 57.1807927] } },
        { "type": "Feature", "id": 478, "properties": { "store_name": "CIRCLE K", "address": "Cukura iela 3", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0558957, 56.5344847] } },
        { "type": "Feature", "id": 479, "properties": { "store_name": "CIRCLE K", "address": "Dārza iela 36", "postcode": "", "city": "Smiltene" }, "geometry": { "type": "Point", "coordinates": [25.9169206, 57.4208922] } },
        { "type": "Feature", "id": 480, "properties": { "store_name": "CIRCLE K", "address": "Dubultu prospekts 42A", "postcode": "", "city": "Jūrmala" }, "geometry": { "type": "Point", "coordinates": [23.763117, 56.9667384] } },
        { "type": "Feature", "id": 481, "properties": { "store_name": "CIRCLE K", "address": "Duntes iela 15", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.135485, 56.9818064] } },
        { "type": "Feature", "id": 482, "properties": { "store_name": "CIRCLE K", "address": "Dzelzavas iela 14", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1694508, 56.9629637] } },
        { "type": "Feature", "id": 483, "properties": { "store_name": "CIRCLE K", "address": "Dzirciema iela 40", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.05271, 56.9584999] } },
        { "type": "Feature", "id": 484, "properties": { "store_name": "CIRCLE K", "address": "Eksporta iela 1C", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0973524, 56.9555393] } },
        { "type": "Feature", "id": 485, "properties": { "store_name": "CIRCLE K", "address": "Esperanto iela 2/8", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0179611, 56.5156417] } },
        { "type": "Feature", "id": 486, "properties": { "store_name": "CIRCLE K", "address": "Ganību iela 171", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0171529, 56.487298] } },
        { "type": "Feature", "id": 487, "properties": { "store_name": "CIRCLE K", "address": "Ganību iela 22", "postcode": "", "city": "Kuldīga" }, "geometry": { "type": "Point", "coordinates": [21.9918488, 56.9581995] } },
        { "type": "Feature", "id": 488, "properties": { "store_name": "CIRCLE K", "address": "Gunāra Astras iela 10", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1868356, 56.9503189] } },
        { "type": "Feature", "id": 489, "properties": { "store_name": "CIRCLE K", "address": "Gunāra Astras iela 17", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.185169, 56.9502779] } },
        { "type": "Feature", "id": 490, "properties": { "store_name": "CIRCLE K", "address": "Jāņa Čakstes iela 1", "postcode": "", "city": "Dobele" }, "geometry": { "type": "Point", "coordinates": [23.2877773, 56.6261053] } },
        { "type": "Feature", "id": 491, "properties": { "store_name": "CIRCLE K", "address": "Jāņavārtu iela 21", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1885028, 56.9237812] } },
        { "type": "Feature", "id": 492, "properties": { "store_name": "CIRCLE K", "address": "Jaunratnieki, Ģibuļu pag., Talsu nov.", "postcode": "", "city": "Ģibuļu pagasts" }, "geometry": { "type": "Point", "coordinates": [22.335824, 57.2190183] } },
        { "type": "Feature", "id": 493, "properties": { "store_name": "CIRCLE K", "address": "Juglas iela 2C", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2436388, 56.98928] } },
        { "type": "Feature", "id": 494, "properties": { "store_name": "CIRCLE K", "address": "Jūrmalas gatve 5A", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0543293, 56.9519311] } },
        { "type": "Feature", "id": 495, "properties": { "store_name": "CIRCLE K", "address": "Kalnciema iela 88", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0477926, 56.937391] } },
        { "type": "Feature", "id": 496, "properties": { "store_name": "CIRCLE K", "address": "Kandavas iela 7", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0607104, 56.9515258] } },
        { "type": "Feature", "id": 497, "properties": { "store_name": "CIRCLE K", "address": "Kārļa Ulmaņa gatve 110", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.006333, 56.9344379] } },
        { "type": "Feature", "id": 498, "properties": { "store_name": "CIRCLE K", "address": "Kārļa Ulmaņa gatve 117", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0040825, 56.9337279] } },
        { "type": "Feature", "id": 499, "properties": { "store_name": "CIRCLE K", "address": "Kārļa Ulmaņa gatve 127", "postcode": "", "city": "Mārupe" }, "geometry": { "type": "Point", "coordinates": [24.016782, 56.928571] } },
        { "type": "Feature", "id": 500, "properties": { "store_name": "CIRCLE K", "address": "Katlakalna iela 16", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1994675, 56.9309417] } },
        { "type": "Feature", "id": 501, "properties": { "store_name": "CIRCLE K", "address": "Krasta iela 101", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1629464, 56.9235558] } },
        { "type": "Feature", "id": 502, "properties": { "store_name": "CIRCLE K", "address": "Krasta iela 47", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.138919, 56.9342266] } },
        { "type": "Feature", "id": 503, "properties": { "store_name": "CIRCLE K", "address": "Krasta iela 70", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1576167, 56.928347] } },
        { "type": "Feature", "id": 504, "properties": { "store_name": "CIRCLE K", "address": "Krišjāņa Valdemāra iela 104", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1342858, 56.9712215] } },
        { "type": "Feature", "id": 505, "properties": { "store_name": "CIRCLE K", "address": "Kurbada iela 8", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.136719, 56.9476395] } },
        { "type": "Feature", "id": 506, "properties": { "store_name": "CIRCLE K", "address": "Kuršu iela 1", "postcode": "", "city": "Dreiliņi" }, "geometry": { "type": "Point", "coordinates": [24.258778, 56.9438215] } },
        { "type": "Feature", "id": 507, "properties": { "store_name": "CIRCLE K", "address": "Kuršu Priedes, Saulkrastu pag.", "postcode": "", "city": "Saulkrastu pagasts" }, "geometry": { "type": "Point", "coordinates": [24.4479766, 57.24354] } },
        { "type": "Feature", "id": 508, "properties": { "store_name": "CIRCLE K", "address": "Kuršu Priedes, Saulkrastu pag., veikals Kurši- 2", "postcode": "", "city": "Saulkrastu pagasts" }, "geometry": { "type": "Point", "coordinates": [24.4479766, 57.24354] } },
        { "type": "Feature", "id": 509, "properties": { "store_name": "CIRCLE K", "address": "Lubānas iela 119A", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2026718, 56.9322128] } },
        { "type": "Feature", "id": 510, "properties": { "store_name": "CIRCLE K", "address": "Maskavas iela 349B", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1891621, 56.9064825] } },
        { "type": "Feature", "id": 511, "properties": { "store_name": "CIRCLE K", "address": "Mazā Saules iela 2", "postcode": "", "city": "Sigulda" }, "geometry": { "type": "Point", "coordinates": [24.8430533, 57.1430817] } },
        { "type": "Feature", "id": 512, "properties": { "store_name": "CIRCLE K", "address": "Mazcuntes, Kandavas pag., Kandavas nov.", "postcode": "", "city": "Kandavas pagasts" }, "geometry": { "type": "Point", "coordinates": [22.8191189, 57.0846409] } },
        { "type": "Feature", "id": 513, "properties": { "store_name": "CIRCLE K", "address": "Meža iela 30", "postcode": "", "city": "Tukums" }, "geometry": { "type": "Point", "coordinates": [23.1684758, 56.9701994] } },
        { "type": "Feature", "id": 514, "properties": { "store_name": "CIRCLE K", "address": "Mīlgrāvja iela 10", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1379339, 57.0238202] } },
        { "type": "Feature", "id": 515, "properties": { "store_name": "CIRCLE K", "address": "Muitas iela 4", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1006847, 56.9525825] } },
        { "type": "Feature", "id": 516, "properties": { "store_name": "CIRCLE K", "address": "Mūkusalas iela 75", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1082541, 56.9217643] } },
        { "type": "Feature", "id": 517, "properties": { "store_name": "CIRCLE K", "address": "Ozolnieki, Ceraukstes pag., Bauskas nov.", "postcode": "", "city": "Ceraukstes pagasts" }, "geometry": { "type": "Point", "coordinates": [24.2875998, 56.369368] } },
        { "type": "Feature", "id": 518, "properties": { "store_name": "CIRCLE K", "address": "Pērnavas iela 7", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1503617, 56.9642923] } },
        { "type": "Feature", "id": 519, "properties": { "store_name": "CIRCLE K", "address": "Piebalgas iela 91", "postcode": "", "city": "Cēsis" }, "geometry": { "type": "Point", "coordinates": [25.3004506, 57.2982349] } },
        { "type": "Feature", "id": 520, "properties": { "store_name": "CIRCLE K", "address": "Priežkalni 2", "postcode": "", "city": "Jumpravas pagasts" }, "geometry": { "type": "Point", "coordinates": [24.9733967, 56.6788348] } },
        { "type": "Feature", "id": 521, "properties": { "store_name": "CIRCLE K", "address": "Rīgas iela 107", "postcode": "", "city": "Ķekava" }, "geometry": { "type": "Point", "coordinates": [24.2466226, 56.8216469] } },
        { "type": "Feature", "id": 522, "properties": { "store_name": "CIRCLE K", "address": "Rīgas iela 165a", "postcode": "", "city": "Krāslava" }, "geometry": { "type": "Point", "coordinates": [27.184173, 55.8881214] } },
        { "type": "Feature", "id": 523, "properties": { "store_name": "CIRCLE K", "address": "Rīgas iela 19", "postcode": "", "city": "Ogre" }, "geometry": { "type": "Point", "coordinates": [24.576838, 56.817984] } },
        { "type": "Feature", "id": 524, "properties": { "store_name": "CIRCLE K", "address": "Rīgas iela 218G", "postcode": "", "city": "Jēkabpils" }, "geometry": { "type": "Point", "coordinates": [25.8561683, 56.5162768] } },
        { "type": "Feature", "id": 525, "properties": { "store_name": "CIRCLE K", "address": "Rīgas-Siguldas šoseja 1", "postcode": "", "city": "Berģi" }, "geometry": { "type": "Point", "coordinates": [24.2915664, 57.0053827] } },
        { "type": "Feature", "id": 526, "properties": { "store_name": "CIRCLE K", "address": "Rūpniecības iela 4", "postcode": "", "city": "Ventspils" }, "geometry": { "type": "Point", "coordinates": [21.5858087, 57.3851754] } },
        { "type": "Feature", "id": 527, "properties": { "store_name": "CIRCLE K", "address": "Satiksmes iela 29", "postcode": "", "city": "Jelgava" }, "geometry": { "type": "Point", "coordinates": [23.6977021, 56.6557194] } },
        { "type": "Feature", "id": 528, "properties": { "store_name": "CIRCLE K", "address": "Daugmales pag., Ķekavas nov.", "postcode": "", "city": "Daugmales pagasts" }, "geometry": { "type": "Point", "coordinates": [24.4351265, 56.8163523] } },
        { "type": "Feature", "id": 529, "properties": { "store_name": "CIRCLE K", "address": "Preiļu pag., Preiļu nov.", "postcode": "", "city": "Preiļu pagasts" }, "geometry": { "type": "Point", "coordinates": [26.690007, 56.298858] } },
        { "type": "Feature", "id": 530, "properties": { "store_name": "CIRCLE K", "address": "Stacijas iela 29", "postcode": "", "city": "Valmiera" }, "geometry": { "type": "Point", "coordinates": [25.4351922, 57.5270327] } },
        { "type": "Feature", "id": 531, "properties": { "store_name": "CIRCLE K", "address": "Stacijas iela 97A", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.5241221, 55.8762551] } },
        { "type": "Feature", "id": 532, "properties": { "store_name": "CIRCLE K", "address": "Nometņu iela 1", "postcode": "", "city": "Salaspils" }, "geometry": { "type": "Point", "coordinates": [24.3253304, 56.8575918] } },
        { "type": "Feature", "id": 533, "properties": { "store_name": "CIRCLE K", "address": "Tvaika iela 2A", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1222897, 56.9934197] } },
        { "type": "Feature", "id": 534, "properties": { "store_name": "CIRCLE K", "address": "Vaļņu iela 11", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1105388, 56.9500124] } },
        { "type": "Feature", "id": 535, "properties": { "store_name": "CIRCLE K", "address": "Ventspils iela 28", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.5450588, 55.8744803] } },
        { "type": "Feature", "id": 536, "properties": { "store_name": "CIRCLE K", "address": "Ventspils šoseja 70 k-2", "postcode": "", "city": "Jūrmala" }, "geometry": { "type": "Point", "coordinates": [23.5886086, 56.9311239] } },
        { "type": "Feature", "id": 537, "properties": { "store_name": "CIRCLE K", "address": "Vidlauku iela 1", "postcode": "", "city": "Ādaži" }, "geometry": { "type": "Point", "coordinates": [24.323533, 57.0771079] } },
        { "type": "Feature", "id": 538, "properties": { "store_name": "CIRCLE K", "address": "Vienības gatve 105", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0844963, 56.9095654] } },
        { "type": "Feature", "id": 539, "properties": { "store_name": "CIRCLE K", "address": "Viļņu iela 4", "postcode": "", "city": "Salacgrīva" }, "geometry": { "type": "Point", "coordinates": [24.362062, 57.7570316] } },
        { "type": "Feature", "id": 540, "properties": { "store_name": "CIRCLE K", "address": "Zaļā iela 10", "postcode": "", "city": "Bauska" }, "geometry": { "type": "Point", "coordinates": [24.1966382, 56.4059634] } },
        { "type": "Feature", "id": 541, "properties": { "store_name": "CIRCLE K", "address": "Ziepniekkalna iela 9", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1228806, 56.912365] } },
        { "type": "Feature", "id": 542, "properties": { "store_name": "TOP", "address": "Brekši', Babītes pag., Babītes nov.", "postcode": "", "city": "Babītes pagasts" }, "geometry": { "type": "Point", "coordinates": [23.7743237, 56.8919092] } },
        { "type": "Feature", "id": 543, "properties": { "store_name": "TOP", "address": "Dārzciema iela 127", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1823883, 56.9332221] } },
        { "type": "Feature", "id": 544, "properties": { "store_name": "TOP", "address": "Lielceļi', Salaspils pag., Salaspils nov.", "postcode": "", "city": "Salaspils pagasts" }, "geometry": { "type": "Point", "coordinates": [24.3501342, 56.8613765] } },
        { "type": "Feature", "id": 545, "properties": { "store_name": "TOP", "address": "Lietuvas šoseja 2A", "postcode": "", "city": "Jelgava" }, "geometry": { "type": "Point", "coordinates": [23.7279467, 56.635621] } },
        { "type": "Feature", "id": 546, "properties": { "store_name": "TOP", "address": "Rīgas iela 69", "postcode": "", "city": "Ādaži" }, "geometry": { "type": "Point", "coordinates": [24.3126085, 57.0942832] } },
        { "type": "Feature", "id": 547, "properties": { "store_name": "TOP", "address": "Rīgas iela 88", "postcode": "", "city": "Valmiera" }, "geometry": { "type": "Point", "coordinates": [25.3872022, 57.5298115] } },
        { "type": "Feature", "id": 548, "properties": { "store_name": "TOP", "address": "Vēja ziedi', Piņķi, Babītes pag.", "postcode": "", "city": "Piņķi" }, "geometry": { "type": "Point", "coordinates": [23.9110019, 56.9443493] } },
        { "type": "Feature", "id": 549, "properties": { "store_name": "TOP", "address": "Vigo', Augšlīgatne, Līgatnes pag.", "postcode": "", "city": "Augšlīgatne" }, "geometry": { "type": "Point", "coordinates": [25.034334, 57.183867] } },
        { "type": "Feature", "id": 550, "properties": { "store_name": "TOP", "address": "Atpūtas iela 5", "postcode": "", "city": "Ķekavas pagasts" }, "geometry": { "type": "Point", "coordinates": [24.1474463, 56.9041271] } },
        { "type": "Feature", "id": 551, "properties": { "store_name": "TOP", "address": "Galvenā iela 1", "postcode": "", "city": "Priekule" }, "geometry": { "type": "Point", "coordinates": [21.5852764, 56.4473509] } },
        { "type": "Feature", "id": 552, "properties": { "store_name": "TOP", "address": "Gaujas iela 20", "postcode": "", "city": "Ādaži" }, "geometry": { "type": "Point", "coordinates": [24.328741, 57.078675] } },
        { "type": "Feature", "id": 553, "properties": { "store_name": "TOP", "address": "Rīgas iela 22-NT", "postcode": "", "city": "Saldus" }, "geometry": { "type": "Point", "coordinates": [22.5001989, 56.6664183] } },
        { "type": "Feature", "id": 554, "properties": { "store_name": "TOP", "address": "Smilgas', Medzes pag., Grobiņas nov.", "postcode": "", "city": "Medzes pagasts" }, "geometry": { "type": "Point", "coordinates": [21.1458263, 56.6199929] } },
        { "type": "Feature", "id": 555, "properties": { "store_name": "TOP", "address": "Cēsu iela 18", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1452217, 56.9671386] } },
        { "type": "Feature", "id": 556, "properties": { "store_name": "TOP", "address": "Dobeles šoseja 90", "postcode": "", "city": "Jelgava" }, "geometry": { "type": "Point", "coordinates": [23.6624181, 56.6535223] } },
        { "type": "Feature", "id": 557, "properties": { "store_name": "TOP", "address": "Jāņa Pliekšāna iela 86", "postcode": "", "city": "Jūrmala" }, "geometry": { "type": "Point", "coordinates": [23.8146852, 56.964077] } },
        { "type": "Feature", "id": 558, "properties": { "store_name": "TOP", "address": "Krasta iela 7", "postcode": "", "city": "Dobele" }, "geometry": { "type": "Point", "coordinates": [23.2831951, 56.616304] } },
        { "type": "Feature", "id": 559, "properties": { "store_name": "TOP", "address": "Skolas iela 1B", "postcode": "", "city": "Ozolnieki" }, "geometry": { "type": "Point", "coordinates": [23.7886836, 56.6917187] } },
        { "type": "Feature", "id": 560, "properties": { "store_name": "TOP", "address": "Tērbatas iela 33/35", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1242534, 56.9553039] } },
        { "type": "Feature", "id": 561, "properties": { "store_name": "TOP", "address": "Baznīcas iela 2", "postcode": "", "city": "Mazsalaca" }, "geometry": { "type": "Point", "coordinates": [25.0565339, 57.8595469] } },
        { "type": "Feature", "id": 562, "properties": { "store_name": "TOP", "address": "Ventas iela 3", "postcode": "", "city": "Skrunda" }, "geometry": { "type": "Point", "coordinates": [22.0208881, 56.6729365] } },
        { "type": "Feature", "id": 563, "properties": { "store_name": "TOP", "address": "Dzelzavas iela 116", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.2156078, 56.9526307] } },
        { "type": "Feature", "id": 564, "properties": { "store_name": "TOP", "address": "Dzelzavas iela 36", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1740578, 56.9606284] } },
        { "type": "Feature", "id": 565, "properties": { "store_name": "TOP", "address": "Brīvības iela 15", "postcode": "", "city": "Vaiņodes" }, "geometry": { "type": "Point", "coordinates": [21.8552532, 56.4168873] } },
        { "type": "Feature", "id": 566, "properties": { "store_name": "TOP", "address": "Gads', Nīcas pag., Nīcas nov.", "postcode": "", "city": "Nīcas pagasts" }, "geometry": { "type": "Point", "coordinates": [21.0614018, 56.3460414] } },
        { "type": "Feature", "id": 567, "properties": { "store_name": "TOP", "address": "Lielā iela 74", "postcode": "", "city": "Grobiņa" }, "geometry": { "type": "Point", "coordinates": [21.1681805, 56.5351055] } },
        { "type": "Feature", "id": 568, "properties": { "store_name": "TOP", "address": "M.Namiķa iela 1-NT7", "postcode": "", "city": "Grobiņa" }, "geometry": { "type": "Point", "coordinates": [21.1568458, 56.5321224] } },
        { "type": "Feature", "id": 569, "properties": { "store_name": "TOP", "address": "Veikalnieki', Vērgales pag., Pāvilostas nov.", "postcode": "", "city": "Vērgales pagasts" }, "geometry": { "type": "Point", "coordinates": [21.1972412, 56.698091] } },
        { "type": "Feature", "id": 570, "properties": { "store_name": "TOP", "address": "Ausmas iela 20", "postcode": "", "city": "Dobele" }, "geometry": { "type": "Point", "coordinates": [23.2856954, 56.6118532] } },
        { "type": "Feature", "id": 571, "properties": { "store_name": "TOP", "address": "Pārupes iela 6", "postcode": "", "city": "Baldone" }, "geometry": { "type": "Point", "coordinates": [24.3886025, 56.7388563] } },
        { "type": "Feature", "id": 572, "properties": { "store_name": "TOP", "address": "Rīgas iela 5", "postcode": "", "city": "Piņķi" }, "geometry": { "type": "Point", "coordinates": [23.9100386, 56.9440727] } },
        { "type": "Feature", "id": 573, "properties": { "store_name": "TOP", "address": "Skolas iela 4", "postcode": "", "city": "Nākotne" }, "geometry": { "type": "Point", "coordinates": [23.4399636, 56.6198211] } },
        { "type": "Feature", "id": 574, "properties": { "store_name": "TOP", "address": "Zalves iela 46", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0368735, 56.9390339] } },
        { "type": "Feature", "id": 575, "properties": { "store_name": "TOP", "address": "Ezeriņi', Cīravas pag., Aizputes nov.", "postcode": "", "city": "Cīravas pagasts" }, "geometry": { "type": "Point", "coordinates": [21.3892607, 56.7334816] } },
        { "type": "Feature", "id": 576, "properties": { "store_name": "TOP", "address": "Kārklu iela 1", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0846987, 56.548291] } },
        { "type": "Feature", "id": 577, "properties": { "store_name": "TOP", "address": "Peldu iela 44-NT7", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.003524, 56.5061523] } },
        { "type": "Feature", "id": 578, "properties": { "store_name": "TOP", "address": "Baznīcas iela 1", "postcode": "", "city": "Auce" }, "geometry": { "type": "Point", "coordinates": [22.892117, 56.464786] } },
        { "type": "Feature", "id": 579, "properties": { "store_name": "TOP", "address": "Cepļu iela 1", "postcode": "", "city": "Staļģene" }, "geometry": { "type": "Point", "coordinates": [23.9512163, 56.5701913] } },
        { "type": "Feature", "id": 580, "properties": { "store_name": "TOP", "address": "Edvarta Virzas iela 2", "postcode": "", "city": "Iecava" }, "geometry": { "type": "Point", "coordinates": [24.2066501, 56.595062] } },
        { "type": "Feature", "id": 581, "properties": { "store_name": "TOP", "address": "Skolas iela 2", "postcode": "", "city": "Uzvara" }, "geometry": { "type": "Point", "coordinates": [24.2551931, 56.3181928] } },
        { "type": "Feature", "id": 582, "properties": { "store_name": "TOP", "address": "Kuršu laukums 11", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0109204, 56.5051074] } },
        { "type": "Feature", "id": 583, "properties": { "store_name": "TOP", "address": "Centra iela 7", "postcode": "", "city": "Zaļenieku pagasts" }, "geometry": { "type": "Point", "coordinates": [23.5085404, 56.5046119] } },
        { "type": "Feature", "id": 584, "properties": { "store_name": "TOP", "address": "Jāņkrogs', Jaunpils", "postcode": "", "city": "Jaunpils" }, "geometry": { "type": "Point", "coordinates": [23.0125321, 56.7314559] } },
        { "type": "Feature", "id": 585, "properties": { "store_name": "TOP", "address": "Muldavas iela 3a", "postcode": "", "city": "Dobele" }, "geometry": { "type": "Point", "coordinates": [23.2799356, 56.6283711] } },
        { "type": "Feature", "id": 586, "properties": { "store_name": "TOP", "address": "Upmalas iela 1", "postcode": "", "city": "Bauska" }, "geometry": { "type": "Point", "coordinates": [24.1998378, 56.4054041] } },
        { "type": "Feature", "id": 587, "properties": { "store_name": "TOP", "address": "Pasta iela 7-NT12", "postcode": "", "city": "Aizpute" }, "geometry": { "type": "Point", "coordinates": [21.6039345, 56.721559] } },
        { "type": "Feature", "id": 588, "properties": { "store_name": "TOP", "address": "Zemgaļi', Code", "postcode": "", "city": "Code" }, "geometry": { "type": "Point", "coordinates": [23.7194612, 56.6376715] } },
        { "type": "Feature", "id": 589, "properties": { "store_name": "TOP", "address": "Oskara Kalpaka iela 66-NT30", "postcode": "", "city": "Liepāja" }, "geometry": { "type": "Point", "coordinates": [21.0030797, 56.5316393] } },
        { "type": "Feature", "id": 590, "properties": { "store_name": "TOP", "address": "Rīgas iela 8", "postcode": "", "city": "Saldus" }, "geometry": { "type": "Point", "coordinates": [22.4935554, 56.6640972] } },
        { "type": "Feature", "id": 591, "properties": { "store_name": "TOP", "address": "Tirgus iela 1", "postcode": "", "city": "Pāvilosta" }, "geometry": { "type": "Point", "coordinates": [21.1791096, 56.8887239] } },
        { "type": "Feature", "id": 592, "properties": { "store_name": "TOP", "address": "Ausmas', Usmas pag.", "postcode": "", "city": "Usmas pagasts" }, "geometry": { "type": "Point", "coordinates": [22.1555997, 57.2176119] } },
        { "type": "Feature", "id": 593, "properties": { "store_name": "TOP", "address": "Ilūkstes iela 44A", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1986822, 56.9488955] } },
        { "type": "Feature", "id": 594, "properties": { "store_name": "TOP", "address": "Institūta iela 7", "postcode": "", "city": "Ulbroka" }, "geometry": { "type": "Point", "coordinates": [24.2905211, 56.9417786] } },
        { "type": "Feature", "id": 595, "properties": { "store_name": "TOP", "address": "Liepu iela 4", "postcode": "", "city": "Jaunsvirlaukas pagasts" }, "geometry": { "type": "Point", "coordinates": [23.7721114, 56.6200613] } },
        { "type": "Feature", "id": 596, "properties": { "store_name": "TOP", "address": "Rīgas iela 8", "postcode": "", "city": "Valgundes pagasts" }, "geometry": { "type": "Point", "coordinates": [23.5861656, 56.8428643] } },
        { "type": "Feature", "id": 597, "properties": { "store_name": "TOP", "address": "Arodu iela 21", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.5408649, 55.8781808] } },
        { "type": "Feature", "id": 598, "properties": { "store_name": "TOP", "address": "Purva iela 1", "postcode": "", "city": "Baloži" }, "geometry": { "type": "Point", "coordinates": [24.1196865, 56.8747864] } },
        { "type": "Feature", "id": 599, "properties": { "store_name": "Latvijas Balzāms", "address": "Jelgavas iela 12", "postcode": "", "city": "Jūrmala" }, "geometry": { "type": "Point", "coordinates": [23.8570677, 56.9804624] } },
        { "type": "Feature", "id": 600, "properties": { "store_name": "Latvijas Balzāms", "address": "Jūrmalas iela 14", "postcode": "", "city": "Piņķi" }, "geometry": { "type": "Point", "coordinates": [23.911866, 56.9434221] } },
        { "type": "Feature", "id": 601, "properties": { "store_name": "Latvijas Balzāms", "address": "Krišjāņa Valdemāra iela 34–1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.118507, 56.9588269] } },
        { "type": "Feature", "id": 602, "properties": { "store_name": "Latvijas Balzāms", "address": "Lielais prospekts 40a", "postcode": "", "city": "Ventspils" }, "geometry": { "type": "Point", "coordinates": [21.5682528, 57.3897995] } },
        { "type": "Feature", "id": 603, "properties": { "store_name": "Latvijas Balzāms", "address": "Nometņu iela 60", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0715, 56.9369157] } },
        { "type": "Feature", "id": 604, "properties": { "store_name": "Latvijas Balzāms", "address": "Brīvības gatve 352", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1959201, 56.9819185] } },
        { "type": "Feature", "id": 605, "properties": { "store_name": "Latvijas Balzāms", "address": "Dārza iela 16a", "postcode": "", "city": "Bauska" }, "geometry": { "type": "Point", "coordinates": [24.1961491, 56.408614] } },
        { "type": "Feature", "id": 606, "properties": { "store_name": "Latvijas Balzāms", "address": "Dzirnavu iela 31", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1125858, 56.9590851] } },
        { "type": "Feature", "id": 607, "properties": { "store_name": "Latvijas Balzāms", "address": "Jomas iela 64", "postcode": "", "city": "Jūrmala" }, "geometry": { "type": "Point", "coordinates": [23.8048558, 56.9734844] } },
        { "type": "Feature", "id": 608, "properties": { "store_name": "Latvijas Balzāms", "address": "Katrīnas dambis 15", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1015182, 56.9705346] } },
        { "type": "Feature", "id": 609, "properties": { "store_name": "Latvijas Balzāms", "address": "Lāčplēša iela 11", "postcode": "", "city": "Aizkraukle" }, "geometry": { "type": "Point", "coordinates": [25.257028, 56.6007807] } },
        { "type": "Feature", "id": 610, "properties": { "store_name": "Latvijas Balzāms", "address": "Lāčplēša iela 48–1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.128244, 56.952497] } },
        { "type": "Feature", "id": 611, "properties": { "store_name": "Latvijas Balzāms", "address": "Lielā iela 16", "postcode": "", "city": "Jelgava" }, "geometry": { "type": "Point", "coordinates": [23.7204008, 56.6515309] } },
        { "type": "Feature", "id": 612, "properties": { "store_name": "Latvijas Balzāms", "address": "Marijas iela 1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1193277, 56.9480729] } },
        { "type": "Feature", "id": 613, "properties": { "store_name": "Latvijas Balzāms", "address": "Maskavas iela 297", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1764875, 56.9140914] } },
        { "type": "Feature", "id": 614, "properties": { "store_name": "Latvijas Balzāms", "address": "Matīsa iela 33", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1352561, 56.956374] } },
        { "type": "Feature", "id": 615, "properties": { "store_name": "Latvijas Balzāms", "address": "Matīsa iela 81", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1420727, 56.9531844] } },
        { "type": "Feature", "id": 616, "properties": { "store_name": "Latvijas Balzāms", "address": "Prāgas iela 1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1146827, 56.9450497] } },
        { "type": "Feature", "id": 617, "properties": { "store_name": "Latvijas Balzāms", "address": "Tērbatas iela 37–10", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1244781, 56.955421] } },
        { "type": "Feature", "id": 618, "properties": { "store_name": "Latvijas Balzāms", "address": "Valdeķu iela 62a", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0935811, 56.9013519] } },
        { "type": "Feature", "id": 619, "properties": { "store_name": "Latvijas Balzāms", "address": "Audēju iela 8", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.111753, 56.9471399] } },
        { "type": "Feature", "id": 620, "properties": { "store_name": "Latvijas Balzāms", "address": "Brīvības gatve 218", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.162693, 56.9718178] } },
        { "type": "Feature", "id": 621, "properties": { "store_name": "Latvijas Balzāms", "address": "Brīvības iela 192", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1496708, 56.9676025] } },
        { "type": "Feature", "id": 622, "properties": { "store_name": "Latvijas Balzāms", "address": "Brīvības iela 85", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1309869, 56.960703] } },
        { "type": "Feature", "id": 623, "properties": { "store_name": "Latvijas Balzāms", "address": "Brīvības iela 90", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1302319, 56.9599303] } },
        { "type": "Feature", "id": 624, "properties": { "store_name": "Latvijas Balzāms", "address": "Dzirnavu iela 98", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1244627, 56.949822] } },
        { "type": "Feature", "id": 625, "properties": { "store_name": "Latvijas Balzāms", "address": "Ģertrūdes iela 5A–1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1207402, 56.9587203] } },
        { "type": "Feature", "id": 626, "properties": { "store_name": "Latvijas Balzāms", "address": "Jomas iela 41", "postcode": "", "city": "Jūrmala" }, "geometry": { "type": "Point", "coordinates": [23.8007904, 56.9730897] } },
        { "type": "Feature", "id": 627, "properties": { "store_name": "Latvijas Balzāms", "address": "Katlakalna iela 1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1893794, 56.9261908] } },
        { "type": "Feature", "id": 628, "properties": { "store_name": "Latvijas Balzāms", "address": "Krišjāņa Barona iela 24/26", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1225379, 56.9516646] } },
        { "type": "Feature", "id": 629, "properties": { "store_name": "Latvijas Balzāms", "address": "Mālkalnes prospekts 4", "postcode": "", "city": "Ogre" }, "geometry": { "type": "Point", "coordinates": [24.5963498, 56.821659] } },
        { "type": "Feature", "id": 630, "properties": { "store_name": "Latvijas Balzāms", "address": "Maskavas iela 6", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1152257, 56.942196] } },
        { "type": "Feature", "id": 631, "properties": { "store_name": "Latvijas Balzāms", "address": "Matīsa iela 13", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1318462, 56.957947] } },
        { "type": "Feature", "id": 632, "properties": { "store_name": "Latvijas Balzāms", "address": "Meža prospekts 5", "postcode": "", "city": "Ogre" }, "geometry": { "type": "Point", "coordinates": [24.5993596, 56.8209108] } },
        { "type": "Feature", "id": 633, "properties": { "store_name": "Latvijas Balzāms", "address": "Palīdzības iela 1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1289214, 56.9647416] } },
        { "type": "Feature", "id": 634, "properties": { "store_name": "Latvijas Balzāms", "address": "Rostokas iela 7", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.0001478, 56.9498277] } },
        { "type": "Feature", "id": 635, "properties": { "store_name": "Latvijas Balzāms", "address": "Sapieru iela 2", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1530888, 56.9637442] } },
        { "type": "Feature", "id": 636, "properties": { "store_name": "Latvijas Balzāms", "address": "Slokas iela 161", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.032937, 56.960276] } },
        { "type": "Feature", "id": 637, "properties": { "store_name": "Latvijas Balzāms", "address": "Smilšu iela 16", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1074998, 56.9507786] } },
        { "type": "Feature", "id": 638, "properties": { "store_name": "Latvijas Balzāms", "address": "Vaļņu iela 21", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1118515, 56.9491758] } },
        { "type": "Feature", "id": 639, "properties": { "store_name": "Latvijas Balzāms", "address": "Augusta Deglava iela 69b", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1952254, 56.948166] } },
        { "type": "Feature", "id": 640, "properties": { "store_name": "Latvijas Balzāms", "address": "Dzirnavu iela 68", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.121613, 56.9527905] } },
        { "type": "Feature", "id": 641, "properties": { "store_name": "Latvijas Balzāms", "address": "Kaļķu iela 2–1", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.1076126, 56.9480359] } },
        { "type": "Feature", "id": 642, "properties": { "store_name": "Latvijas Balzāms", "address": "Raiņa iela 9", "postcode": "", "city": "Valka" }, "geometry": { "type": "Point", "coordinates": [26.0196943, 57.774846] } },
        { "type": "Feature", "id": 643, "properties": { "store_name": "Latvijas Balzāms", "address": "Rīgas iela 44", "postcode": "", "city": "Daugavpils" }, "geometry": { "type": "Point", "coordinates": [26.5208309, 55.8722243] } },
        { "type": "Feature", "id": 644, "properties": { "store_name": "Latvijas Balzāms", "address": "Brīvības gatve 312", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.186503, 56.9790041] } },
        { "type": "Feature", "id": 645, "properties": { "store_name": "Latvijas Balzāms", "address": "Šķūņu iela 16", "postcode": "", "city": "Rīga" }, "geometry": { "type": "Point", "coordinates": [24.106861, 56.9491761] } },
    ]
};

export default stores;