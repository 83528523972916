import React, { useState, useEffect } from "react";
import veloLogoSvg from "../../assets/VeloPage/velo-logo.svg";
import { useModalContext } from "../../context/ModalContext";
import { useLocalStorage } from "../../hook/useLocalStorage";
import { SetCookie, RemoveCookie, GetCookie } from "../../hook/cookie";

let b = 0;
const CookieModal = ({ langData }) => {

  if (b === 0) {
    SetCookie('technical', 'true');
    SetCookie('performance', 'false');
    SetCookie('communication', 'false');
    SetCookie('age', 'true');
    b = 1;
  };

  const initialCookieState = {
    technical: true,
    performance: false,
    communication: false,
    age: true,
  };

  const { handleCloseCookies } = useModalContext();
  const tabs = ["technical", "performance", "communication"];
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const currentTabName = tabs[currentTabIndex];
  const [expandOptions, setExpandOptions] = useState(false);
  const [value, setValue] = useLocalStorage(
    "cookieOptions",
    initialCookieState
  );
  const [cookieOptions, setCookieOptions] = useState(value);

  useEffect(() => {
    setValue(cookieOptions);
    if (cookieOptions.communication && window.dataLayer) {
      window.dataLayer.push({
        event: "GTMcookieOK",
      });
    }
  }, [cookieOptions, setValue]);

  const toggleCookie = (name) => {
    const tabName = tabs[currentTabIndex];
    if (name !== "technical") {
      setCookieOptions((opts) => ({
        ...opts,
        [tabName]: !opts[tabName],
      }));
      if (GetCookie(name) === 'true') {
        RemoveCookie(name);
        SetCookie(name, 'false');
      } else if (GetCookie(name) === 'false') {
        RemoveCookie(name);
        SetCookie(name, 'true');
      }
    }
  };

  const handleChangeTabIndex = (index) => {
    setCurrentTabIndex(index);
  };
  const handleAcceptAllCookies = () => {
    setCookieOptions({
      technical: true,
      performance: true,
      communication: true,
      age: true
    });
    handleCloseCookies();
    RemoveCookie('technical');
    RemoveCookie('performance');
    RemoveCookie('communication');
    RemoveCookie('age');

    SetCookie('technical', true);
    SetCookie('performance', true);
    SetCookie('communication', true);
    SetCookie('age', true);
  };

  return (
    <div className="modal-cookie-wrapper">
      <div className="modal-cookie-wrapper__logo">
        <img src={veloLogoSvg} alt="velo" />
      </div>
      {expandOptions ? (
        <div className="modal-cookie-tabs">
          <button
            onClick={handleCloseCookies}
            className="modal-cookie-tabs__close-btn"
          ></button>
          <div className="modal-cookie-tabs__top">
            <div className="modal-cookie-tabs__cta-wrapper">
              <button
                onClick={() => handleChangeTabIndex(0)}
                className={
                  currentTabIndex === 0
                    ? "modal-cookie-tabs__tab-btn modal-cookie-tabs__tab-btn--active"
                    : "modal-cookie-tabs__tab-btn"
                }
              >
                {langData.cookies.functional}
              </button>
              <button
                onClick={() => handleChangeTabIndex(1)}
                className={
                  currentTabIndex === 1
                    ? "modal-cookie-tabs__tab-btn modal-cookie-tabs__tab-btn--active"
                    : "modal-cookie-tabs__tab-btn"
                }
              >
                {langData.cookies.performance}
              </button>
              <button
                onClick={() => handleChangeTabIndex(2)}
                className={
                  currentTabIndex === 2
                    ? "modal-cookie-tabs__tab-btn modal-cookie-tabs__tab-btn--active"
                    : "modal-cookie-tabs__tab-btn"
                }
              >
                {langData.cookies.tracking}
              </button>
            </div>
          </div>
          <div className="modal-cookie-tabs__body">
            {currentTabIndex === 0 && (
              <p className="modal-cookie-tabs__desc">
                <strong>{langData.cookies.functional}</strong>
                <br /> {langData.cookies.functional_text}
              </p>
            )}
            {currentTabIndex === 1 && (
              <p className="modal-cookie-tabs__desc">
                <strong>{langData.cookies.performance}</strong>
                <br /> {langData.cookies.performance_text}
              </p>
            )}
            {currentTabIndex === 2 && (
              <p className="modal-cookie-tabs__desc">
                <strong>{langData.cookies.tracking}</strong>
                <br />{langData.cookies.tracking_text}
              </p>
            )}
            <div className="modal-switch">
              <div className="modal-switch__content">
                <p>
                  {cookieOptions[tabs[currentTabIndex]]
                    ? langData.cookies.accepted
                    : langData.cookies.notaccepted}
                </p>
              </div>
              <button
                onClick={() => toggleCookie(currentTabName)}
                className="modal-switch-btn"
              >
                <span
                  className={
                    cookieOptions[tabs[currentTabIndex]]
                      ? "modal-switch-btn__bullet modal-switch-btn__bullet--active"
                      : "modal-switch-btn__bullet"
                  }
                ></span>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="modal-cookie">
          <button
            onClick={handleCloseCookies}
            className="modal-cookie__close-btn"
          ></button>
          <div className="modal-cookie__content">
            <p className="modal-cookie__paragraph">
              <strong>{langData.cookies.title}</strong>
            </p>
            <p className="modal-cookie__paragraph">
              {langData.cookies.text}
            </p>
            <p className="modal-cookie__paragraph">
              {langData.cookies.text_2} <a href="/assets/sikdatnu-uzstadijumi.pdf">sīkfailu politiku</a>
            </p>
          </div>
          <div className="modal-cookie__btn-group">
            <button
              onClick={handleAcceptAllCookies}
              className="modal-cookie__btn"
            >
              {langData.cookies.accept}
            </button>
            <button
              onClick={() => setExpandOptions(true)}
              className="modal-cookie__btn"
            >
              {langData.cookies.options}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookieModal;
